import styled from "@emotion/styled";

export const Section = styled.section``;

export const Container = styled.div`
  max-width: 65.625rem;
  margin: 0 auto;
  padding: 9.375rem 1.25rem;
  @media (max-width: 767px) {
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
  }
`;

export const Article = styled.article``;

export const Paragraph = styled.div`
  margin: 3.125rem 0rem;
`;

export const OrderedList = styled.ol``;

export const UnorderedList = styled.ul``;

export const ListItem = styled.li`
  font-family: "Poppins", sans-serif;
  color: grey;
  line-height: 1.7rem;
  font-size: 1rem;
  margin-top: 0.1563rem;
  margin-bottom: 0.4688rem;
  a {
    color: #2f8de4;
  }
  ul {
    padding-bottom: 20px;
  }
`;

export const Bold = styled.span`
  font-weight: 500;
  color: #3b3b3b;
`;

export const InfoBox = styled.div`
  border: 0.125rem dashed #2f8de4;
  background-color: #e5f0f9;
  padding: 1.25rem;
  border-radius: 1rem;
`;
