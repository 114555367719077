import { Link } from "react-router-dom";
import Button from "../../../library/button";
import { Text } from "../../../library/text";
import { Title } from "../../../library/title";
import { getIcon } from "../../../utils/get-icon";
import {
  Container,
  SelectionsWrapper,
  InnerWrapper,
  SelectionCard,
  IconWrapper,
  TopWrapper,
  ContactWrapper,
} from "./styled";

const Content = () => {
  return (
    <Container>
      <InnerWrapper>
        <SelectionsWrapper>
          <Link to="/help-center/supported-software">
            <SelectionCard>
              <TopWrapper>
                <IconWrapper>{getIcon("camera")}</IconWrapper>
                <IconWrapper className="small">
                  {getIcon("external-link")}
                </IconWrapper>
              </TopWrapper>
              <Title size="xs">Støttede programmer</Title>
              <Text color="dark">
                Se hvilke programmer som fungerer med Randi, og lær hvordan du
                enkelt kan bruke dem i din arbeidsflyt.
              </Text>
            </SelectionCard>
          </Link>
        </SelectionsWrapper>
        <ContactWrapper>
          <Title size="md">Har du ikke funnet svar på det du lurer på?</Title>
          <Link to="/contact">
            <Button color="blue" variant="regular">
              Kontakt oss
            </Button>
          </Link>
        </ContactWrapper>
      </InnerWrapper>
    </Container>
  );
};

export default Content;
