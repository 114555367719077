import {
  HeaderWrapper,
  InnerContainer,
  TextWrapper,
  TitleWrapper,
} from "./styled";
import { Text } from "../../../library/text";
import { Title } from "../../../library/title";

const Header = () => {
  return (
    <HeaderWrapper>
      <InnerContainer>
        <TitleWrapper>
          <Title size="xl">Priser for bruk</Title>
        </TitleWrapper>
        <TextWrapper>
          <Text color="dark">
            Hos Randi kan du registrere deg en konto helt gratis uten å måtte
            legge inn kortinformasjon. Som gratisbruker er du begrenset til 2
            transkripter og 2 tekst til tale per måned. Nedenfor kan du se hva
            som er inkludert i Randi Go (betalt abonnement) for kun 349kr
            måneden.
          </Text>
        </TextWrapper>
      </InnerContainer>
    </HeaderWrapper>
  );
};

export default Header;
