import Button from "../../../library/button";
import {
  BrowserMockup,
  BrowserWrapper,
  ButtonsWrapper,
  DetailsWrapper,
  HeaderVideo,
  HeaderWrapper,
  InnerContainer,
  Separator,
  TextWrapper,
  TitleWrapper,
} from "./styled";
import { Link } from "react-router-dom";
import headerVideo from "../../../assets/videos/showcase.mp4";
import { Text } from "../../../library/text";
import { useTranslation } from "react-i18next";
import { Title } from "../../../library/title";
import headerBrowser from "../../../assets/img/header_browser.svg";
import headerBottom from "../../../assets/img/header_separator.svg";

const Header = () => {
  const { t } = useTranslation();

  return (
    <HeaderWrapper>
      <InnerContainer>
        <DetailsWrapper>
          <TitleWrapper>
            <Title size="xl">{t("frontpage.sections.header.title")}</Title>
          </TitleWrapper>
          <TextWrapper>
            <Text color="light">
              {t("frontpage.sections.header.description")}
            </Text>
          </TextWrapper>
          <ButtonsWrapper>
            <Link to="/register">
              <Button
                color="white"
                onClick={() => null}
                variant="regular"
                ariaLabel="Registrer ny bruker"
              >
                {t("frontpage.sections.header.get-started-button")}
              </Button>
            </Link>
            <Link to="/contact">
              <Button variant="regular" color="outline">
                {t("frontpage.sections.header.book-demo-button")}
              </Button>
            </Link>
          </ButtonsWrapper>
        </DetailsWrapper>
        <BrowserWrapper>
          <BrowserMockup src={headerBrowser} />
          <HeaderVideo
            className="video-player"
            width="100%"
            height="100%"
            controls={false}
            muted={true}
            autoPlay={true}
            loop={true}
            playsInline={true}
          >
            <source src={headerVideo} type="video/mp4" />
          </HeaderVideo>
        </BrowserWrapper>
      </InnerContainer>
      <Separator
        src={headerBottom}
        alt={t("frontpage.sections.header.bottom-separator.alt")!}
      />
    </HeaderWrapper>
  );
};

export default Header;
