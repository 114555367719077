import styled from "@emotion/styled";

export const Section = styled.section`
  background-color: #f7fafc;
`;

export const Container = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 0 1.25rem;
  padding-bottom: 2.6875rem;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.25rem;
  gap: 20px;
`;

export const DescriptionWrapper = styled.div`
  display: block;
  max-width: 48.75rem;
  margin: 0 auto;
  margin-top: 0.625rem;
`;

export const LeftArrows = styled.img`
  max-width: 80px;
  @media (max-width: 767px) {
    max-width: 50px;
  }
`;

export const RightArrows = styled.img`
  max-width: 80px;
  @media (max-width: 767px) {
    max-width: 50px;
  }
`;
