import styled from "@emotion/styled";

export const InteractionContainer = styled.div`
  border-color: #ebedf2;
  border-radius: 1rem;
  border-width: 0.125rem;
  border-style: dashed;
  padding: 1.875rem 0.625rem;
  text-align: center;
  position: relative;
  margin-top: 1.5625rem;
  &.error {
    border-color: #ed0c60;
  }
`;
