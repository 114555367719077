import { Helmet } from "react-helmet";

const Meta = () => {
  return (
    <Helmet>
      <title>Randi - Støttede programmer</title>
      <meta name="thumbnail" content="https://randi.ai/showcase.jpg" />
      <meta name="robots" content="index, follow" />
      <meta
        name="description"
        content="Nedenfor finner du en liste over alle programmer og videoredigeringstjenester som støtter automatisk teksting med Randi for en enklere arbeidsflyt."
      />
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:url"
        content="https://randi.ai/help-center/supported-software/"
      />
      <meta name="twitter:title" content="Randi - Støttede programmer" />
      <meta
        name="twitter:description"
        content="Nedenfor finner du en liste over alle programmer og videoredigeringstjenester som støtter automatisk teksting med Randi for en enklere arbeidsflyt."
      />
      <meta name="twitter:image" content="https://randi.ai/showcase.jpg" />

      <meta
        property="og:url"
        content="https://randi.ai/help-center/supported-software/"
      />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Randi - Støttede programmer" />
      <meta
        property="og:description"
        content="Nedenfor finner du en liste over alle programmer og videoredigeringstjenester som støtter automatisk teksting med Randi for en enklere arbeidsflyt."
      />
      <meta property="og:image" content="https://randi.ai/showcase.jpg" />
      <link
        rel="canonical"
        href="https://randi.ai/help-center/supported-software/"
      />
    </Helmet>
  );
};

export default Meta;
