import styled from "@emotion/styled";

export const Section = styled.section`
  background-color: #f6f9fb;
`;

export const Container = styled.div`
  margin: 0 auto;
  padding: 0.3125rem 0rem;
`;

export const Wrapper = styled.div`
  margin-top: 4.6875rem;
  margin-bottom: 4.6875rem;
  @media (max-width: 767px) {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
  padding: 0px 20px;
`;

export const MarqueeWrapper = styled.div`
  margin-top: 0;
`;

export const ScrollWrapper = styled.div`
  margin-top: 50px;
`;
