import { Title } from "../../../library/title";
import {
  Container,
  MarqueeWrapper,
  ScrollWrapper,
  Section,
  TitleWrapper,
  Wrapper,
} from "./styled";
import Marquee from "react-fast-marquee";
import TestimonialBox from "./testimonial-box";
import premiereProLogo from "../../../assets/img/premiere_pro_logo.svg";
import daVinciResolveLogo from "../../../assets/img/davinci_resolve_logo.svg";
import camtasiaStudioLogo from "../../../assets/img/camtasia_studio_logo.svg";
import cyberlinkResolveLogo from "../../../assets/img/cyberlink_resolve_logo.svg";
import finalCutPro from "../../../assets/img/final_cut_pro_logo.svg";
import vegasProLogo from "../../../assets/img/vegas_pro_logo.svg";
import hitFilmProLogo from "../../../assets/img/hitfilm_pro_logo.svg";
import filmoraLogo from "../../../assets/img/filmora_logo.svg";
import blenderStudioLogo from "../../../assets/img/blender_logo.svg";
import afterEffectsLogo from "../../../assets/img/adobe_after_effects_logo.svg";
import avidemuxLogo from "../../../assets/img/avidemux_logo.svg";
import lightworksLogo from "../../../assets/img/lightworks_logo.svg";
import kdensliveLogo from "../../../assets/img/kdenslive_logo.svg";
import movaviLogo from "../../../assets/img/movavi_logo.svg";
import corelVideoStudio from "../../../assets/img/corel_videostudio_logo.svg";
import { useTranslation } from "react-i18next";

const TESTIMONIALS_TOP = [
  {
    thumbnail: premiereProLogo,
    name: "Adobe Premiere Pro",
    url: "/help-center/supported-software/adobe-premiere-pro",
  },
  {
    thumbnail: daVinciResolveLogo,
    name: "DaVinci Resolve",
    url: "/help-center/supported-software/davinci-resolve",
  },
  {
    thumbnail: finalCutPro,
    name: "Final Cut Pro",
    url: "/help-center/supported-software/final-cut-pro",
  },
  {
    thumbnail: camtasiaStudioLogo,
    name: "Camtasia Studio",
    url: "/help-center/supported-software/camtasia-studio",
  },
  {
    thumbnail: cyberlinkResolveLogo,
    name: "Cyberlink PowerDirector",
    url: "/help-center/supported-software/cyberlink-powerdirector",
  },
  {
    thumbnail: vegasProLogo,
    name: "Vegas Pro",
    url: "/help-center/supported-software/vegas-pro",
  },
  {
    thumbnail: hitFilmProLogo,
    name: "HitFilm Pro",
    url: "/help-center/supported-software/hitfilm-pro",
  },
  {
    thumbnail: filmoraLogo,
    name: "Filmora",
    url: "/help-center/supported-software/filmora",
  },
  {
    thumbnail: blenderStudioLogo,
    name: "Blender Studio",
    url: "/help-center/supported-software/blender-studio",
  },
];

const TESTIMONIALS_BOTTOM = [
  {
    thumbnail: corelVideoStudio,
    name: "Corel VideoStudio",
    url: "/help-center/supported-software/corel-videostudio",
  },
  {
    thumbnail: movaviLogo,
    name: "Movavi",
    url: "/help-center/supported-software/movavi",
  },
  {
    thumbnail: kdensliveLogo,
    name: "Kdenslive",
    url: "/help-center/supported-software/kdenslive",
  },
  {
    thumbnail: afterEffectsLogo,
    name: "Adobe After Effects",
    url: "/help-center/supported-software/adobe-after-effects",
  },
  {
    thumbnail: avidemuxLogo,
    name: "Avidemux",
    url: "/help-center/supported-software/avidemux",
  },
  {
    thumbnail: lightworksLogo,
    name: "Lightworks",
    url: "/help-center/supported-software/lightworks",
  },
];

const Index = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Container>
        <Wrapper>
          <TitleWrapper>
            <Title>
              {t("frontpage.sections.supported-programs-view.title")}
            </Title>
          </TitleWrapper>
          <ScrollWrapper>
            <MarqueeWrapper>
              <Marquee
                direction="right"
                autoFill
                pauseOnHover
                pauseOnClick
                speed={15}
              >
                {TESTIMONIALS_TOP?.map((testimonial, i) => (
                  <TestimonialBox
                    key={i}
                    thumbnail={testimonial?.thumbnail}
                    name={testimonial?.name}
                    url={testimonial?.url}
                  />
                ))}
              </Marquee>
            </MarqueeWrapper>
            <MarqueeWrapper>
              <Marquee
                direction="left"
                autoFill
                pauseOnHover
                pauseOnClick
                speed={15}
              >
                {TESTIMONIALS_BOTTOM?.map((testimonial, i) => (
                  <TestimonialBox
                    key={i}
                    thumbnail={testimonial?.thumbnail}
                    name={testimonial?.name}
                    url={testimonial?.url}
                  />
                ))}
              </Marquee>
            </MarqueeWrapper>
          </ScrollWrapper>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default Index;
