import {
  Container,
  InnerWrapper,
  Section,
  TextWrapper,
  IllustrationWrapper,
  OrderedList,
  ListItem,
  Bold,
  Illustration,
  IllustrationArrow,
  BottomTextWrapper,
} from "./styled";
import { Title } from "../../../library/title";
import { Text } from "../../../library/text";
import Button from "../../../library/button";
import illustrationArrow from "../../../assets/img/illustration_arrow.svg";
import uploadThumbnail from "../../../assets/img/upload-thumbnail.svg";
import importThumbnail from "../../../assets/img/import-thumbnail.svg";
import { Link } from "react-router-dom";

const Content = () => {
  return (
    <Container>
      <InnerWrapper>
        <Section bottom={false}>
          <TextWrapper>
            <Title>Steg 1 - transkriber med Randi</Title>
            <Text color="dark">
              Logg inn på Randi-brukeren din og trykk på "Transkripter" øverst i
              menyen og gjør deretter følgende:
            </Text>
            <OrderedList>
              <ListItem>
                Trykk på <Bold>Nytt transkript</Bold>-knappen øverst til høyre.
              </ListItem>
              <ListItem>
                Fra modalen som dukker opp, trykk på <Bold>Velg fil</Bold> eller
                dra og slipp ønsket fil inn i filvalg-boksen.
              </ListItem>
              <ListItem>
                Velg om filen skal være kun tilgjengelig for deg selv eller
                andre i arbeidsmiljøet ditt og trykk på <Bold>Gå videre</Bold>.
              </ListItem>
              <ListItem>
                Velg deretter språket du ønsker at underteksten din skal tekstes
                til og juster <Bold>Språklig frihet</Bold> etter behov. Trykk så{" "}
                <Bold>Gå videre</Bold>.
              </ListItem>
              <ListItem>
                Til slutt velger du hva slags format du ønsker at underteksten
                din skal ha (tekstlengde) og trykk til slutt på{" "}
                <Bold>Start transkribering</Bold>.
              </ListItem>
            </OrderedList>
            <Text color="dark">
              Trykk til slutt på <Bold>Last ned</Bold> og velg enten VTT eller
              SRT-format - Adobe Premiere Pro støtter begge.
            </Text>
          </TextWrapper>
          <IllustrationWrapper>
            <Illustration src={uploadThumbnail} />
          </IllustrationWrapper>
          <IllustrationArrow src={illustrationArrow} />
        </Section>
        <Section bottom={false}>
          <IllustrationWrapper>
            <Illustration src={importThumbnail} />
          </IllustrationWrapper>
          <TextWrapper>
            <Title>Steg 2 - importer til Adobe Premiere Pro</Title>
            <Text color="dark">
              Etter at du har lastet ned undertekstfilen din fra Randi, kan du
              enkelt importere den i Adobe Premiere Pro ved å følge disse
              stegene:
            </Text>
            <OrderedList>
              <ListItem>
                Åpne <Bold>Adobe Premiere Pro</Bold> og gå til prosjektpanelet.
              </ListItem>
              <ListItem>
                Høyreklikk inne i prosjektpanelet og velg <Bold>Importer</Bold>,
                eller dra og slipp undertekstfilen (SRT/VTT) direkte inn.
              </ListItem>
              <ListItem>
                Når filen er importert, dra den til <Bold>tidslinjen</Bold> over
                videosporet ditt.
              </ListItem>
              <ListItem>
                Juster plasseringen av undertekstene etter behov, og tilpass
                stil og utseende via <Bold>Essential Graphics</Bold>-panelet.
              </ListItem>
              <ListItem>
                Når du er fornøyd, eksporter videoen din ved å gå til{" "}
                <Bold>
                  File {">"} Export {">"} Media
                </Bold>
                , og sørg for at undertekster er inkludert i
                eksportinnstillingene.
              </ListItem>
            </OrderedList>
          </TextWrapper>
        </Section>
        <Section bottom={true}>
          <Title>Tekst din video gratis med Randi</Title>
          <BottomTextWrapper>
            <Text color="dark">
              Kom i gang med automatisk teksting av video helt gratis og få opp
              til 2 opplastinger hver måned på videoer opp til 20 minutter.
            </Text>
          </BottomTextWrapper>
          <Link to="/register">
            <Button color="blue" variant="regular">
              Registrer gratis bruker
            </Button>
          </Link>
        </Section>
      </InnerWrapper>
    </Container>
  );
};

export default Content;
