import styled from "@emotion/styled";

export const Container = styled.div`
  background-color: #f7fafc;
`;

export const InnerWrapper = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 0rem 1.25rem;
`;

export const SelectionsWrapper = styled.div`
  margin: 0 auto;
  display: block;
  padding: 3.125rem 4.6875rem;

  a {
    text-decoration: none;
  }
  @media (max-width: 767px) {
    padding: 2rem 0rem;
  }
`;

export const DetailTitle = styled.span`
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #2f8de4;
`;

export const SelectionCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  flex: 1 1 calc(33.33% - 20px); /* Allows it to shrink and respect 3-column layout */
  min-width: 280px;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
  margin-top: 50px;

  &:hover {
    transform: translateY(-5px);
    cursor: pointer;
  }

  @media (max-width: 980px) {
    flex: 1 1 calc(100% - 20px);
    margin-top: 20px;
    max-width: 100%;
  }
`;

export const IconWrapper = styled.div`
  font-size: 30px;
  margin-bottom: 15px;
  color: #2f8de4;
  font-size: 42px;
  &.small {
    font-size: 20px;
    color: grey;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContactWrapper = styled.div`
  margin: 0 auto;
  display: block;
  padding: 5.125rem 4.6875rem;
  text-align: center;
  button {
    margin-top: 15px;
  }
  @media (max-width: 767px) {
    margin-top: 3.125rem;
    padding: 2rem 0rem;
  }
`;
