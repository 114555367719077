import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const Section = styled.section`
  background-color: #f7fafc;
`;

export const Container = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 12.5rem 1.25rem;
  @media (max-width: 767px) {
    padding-top: 9.375rem;
    padding-bottom: 4.6875rem;
  }
`;

export const ContentWrapper = styled.div``;

export const ContentRow = styled(Row)``;

export const ContentCol = styled(Col)`
  @media (max-width: 767px) {
    &.faq-col {
      margin-top: 3.125rem;
    }
  }
`;

export const FormWrapper = styled.div`
  max-width: 46.875rem;
`;
