import { useTranslation } from "react-i18next";
import { getIcon } from "../../../../../../../utils/get-icon";
import { Wrapper, RemoveButton, FileIcon, FileName, FileSize } from "./styled";
import { filesize } from "filesize";
import { UseFormSetValue } from "react-hook-form";
import { UploadTranscriptSchema } from "../../../schema";

type SelectedViewProps = {
  selectedFile: File;
  setValue: UseFormSetValue<UploadTranscriptSchema>;
};

const DroppedView = ({ selectedFile, setValue }: SelectedViewProps) => {
  const { t } = useTranslation();

  function renderFileSize(bytes: any) {
    return <>{filesize(bytes, { base: 2, standard: "jedec" })}</>;
  }

  const clearSelection = () => {
    setValue("file", null as any);
  };

  return (
    <Wrapper>
      <RemoveButton onClick={clearSelection} type="button">
        {getIcon("close")}
      </RemoveButton>
      <FileIcon>{getIcon("media")}</FileIcon>
      <FileName>
        {selectedFile?.name ?? t("dashboard.universal.text.not-available")}
      </FileName>
      <FileSize>
        {t(
          "dashboard.pages.transcripts.add-modal.content.select-source-step.uploaded-container.file-size"
        )}
        : {selectedFile?.size && renderFileSize(selectedFile?.size)}
      </FileSize>
    </Wrapper>
  );
};

export default DroppedView;
