import { Title } from "../../../library/title";
import { Text } from "../../../library/text";
import {
  BottomWrapper,
  Container,
  FeatureCard,
  FeaturesWrapper,
  IconWrapper,
  Logo,
  LogoContainer,
  LogoWrapper,
  Section,
  TitleWrapper,
} from "./styled";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import splaeshLogo from "../../../assets/img/splaesh-logo.svg";
import garasjetidLogo from "../../../assets/img/garasjetid-logo.svg";
import streamhubLogo from "../../../assets/img/streamhub-logo.svg";
import nattuglaLogo from "../../../assets/img/nattugla-logo.svg";
import pipelifeLogo from "../../../assets/img/pipelife-logo.svg";
import p4Logo from "../../../assets/img/p4-logo.svg";
import { getIcon } from "../../../utils/get-icon";

const Index = () => {
  const { t } = useTranslation();

  const FEATURES = [
    {
      id: 1,
      icon: "window",
      title: t("frontpage.sections.logos-usecases-view.features.box-one.title"),
      text: t("frontpage.sections.logos-usecases-view.features.box-one.text"),
    },
    {
      id: 2,
      icon: "compliance",
      title: t("frontpage.sections.logos-usecases-view.features.box-two.title"),
      text: t("frontpage.sections.logos-usecases-view.features.box-two.text"),
    },
    {
      id: 3,
      icon: "people",
      title: t(
        "frontpage.sections.logos-usecases-view.features.box-three.title"
      ),
      text: t("frontpage.sections.logos-usecases-view.features.box-three.text"),
    },
  ];

  return (
    <Section>
      <Container>
        <TitleWrapper>
          <Title size="sm">
            {t("frontpage.sections.logos-usecases-view.customers-title")}
          </Title>
        </TitleWrapper>
        <LogoContainer>
          <LogoWrapper>
            <Link target="_blank" to="https://splaesh.com/">
              <Logo src={splaeshLogo} />
            </Link>
            <Link target="_blank" to="https://garasjetid.no/">
              <Logo src={garasjetidLogo} />
            </Link>
            <Link target="_blank" to="https://streamhub.no/">
              <Logo src={streamhubLogo} />
            </Link>
            <Link target="_blank" to="https://nattugla.no/">
              <Logo src={nattuglaLogo} />
            </Link>
            <Link target="_blank" to="https://pipelife.no/">
              <Logo src={pipelifeLogo} />
            </Link>
            <Link target="_blank" to="https://p4.no/">
              <Logo className="shrink" src={p4Logo} />
            </Link>
          </LogoWrapper>
        </LogoContainer>
        <BottomWrapper>
          <TitleWrapper>
            <Title>
              {t("frontpage.sections.logos-usecases-view.main-title")}
            </Title>
            <Text color="dark">
              {t("frontpage.sections.logos-usecases-view.main-text")}
            </Text>
          </TitleWrapper>

          <FeaturesWrapper>
            {FEATURES.map((section, index) => (
              <FeatureCard key={`${section.title}-${index}`}>
                <IconWrapper>{getIcon(section.icon)}</IconWrapper>
                <Title size="xs">{section.title}</Title>
                <Text color="dark">{section.text}</Text>
              </FeatureCard>
            ))}
          </FeaturesWrapper>
        </BottomWrapper>
      </Container>
    </Section>
  );
};

export default Index;
