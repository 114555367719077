import Button from "../../../library/button";
import Input from "../../../library/input";
import TableLoader from "../../../library/table-loader";
import Tabs from "../../../library/tabs";
import Table from "./list-view";
import { ContentContainer, SearchContainer, TableWrapper } from "./styled";
import AddModal from "./add-modal";
import SubscriptionModalContent from "../../../components/UpgradeSubscriptionModal";
import useListSoundtracks from "../../../hooks/useSoundtracksList";
import useUser from "../../../hooks/useUser";
import { useTranslation } from "react-i18next";
import {
  clearDeletionArray,
  setSearchQuery,
} from "../../../redux/slices/soundtracks";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../library/notification";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import useDeleteSoundtracks from "../../../hooks/useDeleteSoundtracks";
import AttentionBox from "../../../library/attention-box";
import Modal from "../../../library/modal";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import DashboardTopBar from "../../../components/DashboardTopBar";
import DashboardPage from "../../../components/DashboardPage";
import Confirmation from "../../../library/modal/confirmation";
import { metadata } from "./meta";

const MOBILE_VIEW = 800;

const Content = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<string>("private");
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [newSoundtrackOpen, setNewSoundtrackOpen] = useState<boolean>(false);
  const [subscriptionModalOpen, setSubscriptionModalOpen] =
    useState<boolean>(false);

  const { deleteSoundtracks } = useDeleteSoundtracks();
  const { fetchSoundtracksList, loading } = useListSoundtracks();
  const { loading: loadingUserDetails } = useUser();

  const selectedWorkspace = useAppSelector(
    (state) => state?.user?.selectedWorkspace
  );
  const user = useAppSelector((state) => state?.user?.details);
  const privateSoundtracks = useAppSelector(
    (state) => state?.soundtracks?.records?.private
  );
  const publicSoundtracks = useAppSelector(
    (state) => state?.soundtracks?.records?.public
  );

  const deletions = useAppSelector(
    (state) => state?.soundtracks?.deletionArray
  );
  const searchQuery = useAppSelector(
    (state) => state?.soundtracks?.searchQuery
  );

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const soundtracks = useCallback(
    (type: "private" | "public") => {
      if (searchQuery) {
        if (type === "private") {
          return privateSoundtracks.filter((x: any) =>
            x.title.includes(searchQuery)
          );
        }
        return publicSoundtracks.filter((x: any) =>
          x.title.includes(searchQuery)
        );
      }
      if (type === "private") {
        return privateSoundtracks;
      }
      return publicSoundtracks;
    },
    [privateSoundtracks, publicSoundtracks, searchQuery]
  );

  const renderList = (type: "private" | "public") => {
    if (loading) {
      return (
        <TableLoader
          message={
            t("dashboard.pages.soundtracks.loading-view.message") as string
          }
        />
      );
    }
    return <Table data={soundtracks(type)} />;
  };

  const SELECTIONS = [
    {
      id: 0,
      key: "private",
      title: t(
        "dashboard.pages.soundtracks.tab-selections.private-soundtracks.title"
      ),
      ariaLabel: t(
        "dashboard.pages.soundtracks.tab-selections.private-soundtracks.ariaLabel"
      ) as string,
      display: (
        <TableWrapper className={false ? "loading" : ""}>
          {renderList("private")}
        </TableWrapper>
      ),
    },
    {
      id: 1,
      key: "shared",
      title: t(
        "dashboard.pages.soundtracks.tab-selections.shared-soundtracks.title"
      ),
      ariaLabel: t(
        "dashboard.pages.soundtracks.tab-selections.shared-soundtracks.ariaLabel"
      ) as string,
      display: (
        <TableWrapper className={loading ? "loading" : ""}>
          {renderList("public")}
        </TableWrapper>
      ),
    },
  ];

  useEffect(() => {
    if (selectedWorkspace?.id && user?.uid) {
      fetchSoundtracksList();
    }
  }, [fetchSoundtracksList, selectedWorkspace?.id, user?.uid]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchQuery(e.target.value));
  };

  const openNewSoundtrack = () => {
    setNewSoundtrackOpen(true);
  };

  const closeNewSoundtrack = () => {
    setNewSoundtrackOpen(false);
    fetchSoundtracksList();
  };

  const openSubscriptionModal = () => {
    setSubscriptionModalOpen(true);
  };

  const closeSubscriptionModal = () => {
    setSubscriptionModalOpen(false);
  };

  const renderAttention = () => {
    if (
      !loadingUserDetails &&
      selectedWorkspace?.subscription === "small" &&
      selectedWorkspace?.role === "administrator"
    ) {
      return (
        <AttentionBox
          title={t("dashboard.pages.soundtracks.upgrade-box.title")}
          text={t(t("dashboard.pages.soundtracks.upgrade-box.description"))}
          icon="information"
          type="information"
          buttonText={
            t("dashboard.pages.soundtracks.upgrade-box.button.title") as string
          }
          onClick={openSubscriptionModal}
        />
      );
    }
    return null;
  };

  const performDeleteSoundtracks = async () => {
    try {
      const deletion = await deleteSoundtracks(deletions);
      if (deletion!.error === null) {
        dispatch(clearDeletionArray());
        closeDeleteModal();
        sendSuccessNotification(
          t(
            "dashboard.universal.notifications.soundtracks.delete-multiple.success"
          )
        );
        fetchSoundtracksList();
      } else {
        sendErrorNotification(
          t(
            "dashboard.universal.notifications.soundtracks.delete-multiple.failed"
          )
        );
      }
    } catch (e) {
      sendErrorNotification(
        t(
          "dashboard.universal.notifications.soundtracks.delete-multiple.failed"
        )
      );
    }
  };

  return (
    <DashboardPage
      layout="default"
      meta={metadata}
      notification={renderAttention()}
    >
      <>
        <DashboardTopBar
          title={t("dashboard.pages.soundtracks.title")}
          buttons={
            <>
              <Button
                onClick={openDeleteModal}
                variant={isMobile ? "icon" : "regular"}
                size={isMobile ? "lg" : "md"}
                color="grey"
                icon="trash"
                spacing="right"
                disabled={deletions?.length === 0}
                ariaLabel={
                  t(
                    "dashboard.pages.soundtracks.buttons.remove-transcript.ariaLabel"
                  ) as string
                }
              >
                {t(
                  "dashboard.pages.soundtracks.buttons.remove-soundtrack.title"
                )}
              </Button>
              <Button
                onClick={openNewSoundtrack}
                variant={isMobile ? "icon" : "regular"}
                color="blue"
                icon="plus"
                size={isMobile ? "lg" : "md"}
                ariaLabel={
                  t(
                    "dashboard.pages.soundtracks.buttons.add-soundtrack.ariaLabel"
                  ) as string
                }
              >
                {t("dashboard.pages.soundtracks.buttons.add-soundtrack.title")}
              </Button>
            </>
          }
        />
        <SearchContainer>
          <Input
            variant="search"
            value={searchQuery}
            ariaLabel={
              t("dashboard.pages.soundtracks.search-field.ariaLabel") as string
            }
            placeholder={`${t(
              "dashboard.pages.soundtracks.search-field.placeholder"
            )}..`}
            onChange={handleSearch}
          />
        </SearchContainer>
        <ContentContainer>
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            selections={SELECTIONS}
          />
        </ContentContainer>
        <Modal
          open={newSoundtrackOpen}
          onClose={closeNewSoundtrack}
          title={t("dashboard.pages.soundtracks.add-modal.title")}
        >
          <AddModal closeModal={closeNewSoundtrack} />
        </Modal>
        <Confirmation
          open={showDeleteModal}
          onClose={closeDeleteModal}
          title={t("dashboard.pages.soundtracks.delete-multiple-modal.title")}
          description={
            t("dashboard.pages.soundtracks.delete-multiple-modal.text")!
          }
          variant="delete"
          onSubmit={performDeleteSoundtracks}
          submitText={
            t(
              "dashboard.pages.soundtracks.delete-multiple-modal.buttons.delete.title"
            )!
          }
        />
        <Modal
          open={subscriptionModalOpen}
          onClose={closeSubscriptionModal}
          title={t("dashboard.pages.workspace.change-subscription-modal.title")}
        >
          <SubscriptionModalContent onClose={closeSubscriptionModal} />
        </Modal>
      </>
    </DashboardPage>
  );
};

export default Content;
