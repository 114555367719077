import styled from "@emotion/styled";

export const HeaderWrapper = styled.header`
  width: 100%;
  max-height: 100%;
  background-color: #2f8de4;
  background-size: 100% 100%;
  z-index: 2;
  position: relative;
`;

export const InnerContainer = styled.div`
  padding: 10.0625rem 1.5625rem 0rem 1.5625rem;
  text-align: center;
  @media (max-width: 767px) {
    padding: 7.275rem 1.5625rem 0rem 1.5625rem;
  }
`;

export const TitleWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: block;
  h2 {
    color: #fff;
  }
`;

export const TextWrapper = styled.div`
  max-width: 44rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 0.9375rem;
  margin-bottom: 2.1875rem;
`;

export const HeaderVideo = styled.video`
  position: absolute;
  z-index: 3;
  width: 81.75%;
  height: 76.5%;
  top: 5%;
  left: 9.1%;
  right: 10%;
  object-fit: cover;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
`;

export const Separator = styled.img`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 1;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  button {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    button {
      margin-top: 0.625rem;
    }
  }
`;

export const BrowserWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

export const BrowserMockup = styled.img`
  width: 100%;
  z-index: 2;
  position: relative;
  display: block;
`;

export const DetailsWrapper = styled.div`
  position: relative;
  z-index: 2;
  padding-bottom: 50px;
`;
