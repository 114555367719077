import styled from "@emotion/styled";

export const Section = styled.section`
  position: relative;
  background-color: #2f8de4;
`;

export const Container = styled.div`
  max-width: 78.125rem;
  margin: 0 auto;
  padding: 4.6875rem 1.25rem;
  padding-top: 200px;
  @media (max-width: 767px) {
    padding: 1rem 1.25rem;
  }
`;

export const Wrapper = styled.div`
  margin-top: 1.7625rem;
  margin-bottom: 1.5625rem;
  @media (max-width: 767px) {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
`;

export const Separator = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 1;
`;

export const ShowcaseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 20px;

  @media (max-width: 900px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const IllustrationWrapper = styled.div`
  flex: 1;
`;

export const Illustration = styled.img`
  width: 100%;
  height: auto;
`;

export const DetailsWrapper = styled.div`
  flex: 1;

  a {
    text-decoration: none;
  }
  button {
    display: block;
    margin-top: 1.5625rem;
  }
`;

export const TitleWrapper = styled.div`
  text-align: left;
  h2 {
    color: #fff;
  }
`;

export const TextWrapper = styled.div`
  margin-top: 20px;
  text-align: left;
`;
