import styled from "@emotion/styled";
import { Tab } from "react-bootstrap";

export const TabsWrapper = styled.div`
  min-height: 100% !important;
  width: 100%;

  .nav-tabs {
    width: 100%;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    border-bottom: none;

    .nav-item>
button {
 border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  font-family: "Poppins"; sans-serif;
  background-color: transparent !important;
  font-size: 0.875rem;
  font-weight: 500;
  color: grey;
  &.active{
    color: #2f8de4 !important;
      border-bottom: 0.15rem solid #2f8de4 !important;
  }
  &:hover{
    color: #2f8de4 !important;
  }
}
  }

  @media (max-width: 767px) {
    .nav-tabs {
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow-x: scroll;
    }
  }
`;

export const SingleTab = styled(Tab)``;
