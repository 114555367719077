import styled from "@emotion/styled";

export const PriceDisplayContainer = styled.div`
  border-radius: 1rem;
  border: 0.125rem dashed #e1e1e1;
  padding: 1.875rem 0.625rem;
  text-align: center;
  position: relative;
  margin-bottom: 0.625rem;
  &:last-of-type {
    margin-bottom: 0rem;
  }
  &.active {
    border: 0.125rem dashed #2f8de4;
    background-color: #e5f0f9;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const PriceDisplayRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
`;

export const PriceDetailsCol = styled.div`
  &:nth-of-type(1) {
    text-align: left;
    flex: 0 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:nth-of-type(2) {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-right: 3.125rem !important;
  }
  &:last-of-type {
    flex: 0;
    text-align: right;
    white-space: nowrap;
    padding-right: 0.625rem;
  }
`;

export const SelectButton = styled.button`
  border-radius: 100%;
  height: 1.5625rem;
  width: 1.5625rem;
  font-size: 1.375rem;
  padding: 0rem;
  margin: 0rem;
  justify-content: center;
  color: #2f8de4;
  border: none;
  background: transparent;
`;

export const PlanTitle = styled.span`
  font-family: "Poppins", sans-serif;
  display: block;
  color: #3b3b3b;
  font-size: 1rem;
  font-weight: 600;
`;

export const PlanDescription = styled.span`
  font-family: "Poppins", sans-serif;
  display: block;
  color: grey;
  font-size: 0.875rem;
`;

export const PriceTitle = styled.span`
  font-family: "Poppins", sans-serif;
  color: #3b3b3b;
  font-weight: 600;
  display: block;
`;

export const BillingTitle = styled.span`
  font-family: "Poppins", sans-serif;
  display: block;
  color: grey;
  font-size: 0.875rem;
`;

export const Field = styled.div`
  margin-bottom: 0.9375rem;
  input {
    margin-top: 0.3125rem;
  }
  &:last-of-type {
    margin-bottom: 0rem;
  }
`;

export const ModalContentContainer = styled.div`
  margin-top: 1.5625rem;
  input {
    width: 100%;
  }
`;

export const ModalButtonsContainer = styled.div`
  margin-top: 1.5625rem;
  text-align: center;
`;

export const Form = styled.form``;

export const ToggleWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const DisclaimerText = styled.span`
  font-size: 12px;
  display: block;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  text-align: center;
  font-weight: 400;
  color: grey;
  a {
    color: #2f8de4;
  }
`;
