import { getIcon } from "../../utils/get-icon";
import { StyledButton, StyledIconButton } from "./styled";

interface IButtonProps {
  color: "blue" | "white" | "transparent" | "outline" | "grey" | "red";
  type?: any;
  children?: React.ReactNode;
  onClick?: () => void;
  variant: "regular" | "icon";
  size?: "sm" | "md" | "lg";
  icon?: string;
  spacing?: "right" | "left";
  disabled?: boolean;
  className?: string;
  ariaLabel?: string;
}

const Button: React.FC<IButtonProps> = ({
  color,
  type = "button",
  variant,
  onClick,
  icon = "",
  size = "md",
  spacing,
  className,
  disabled,
  children,
  ariaLabel,
}) => {
  if (variant === "regular") {
    return (
      <StyledButton
        type={type}
        onClick={onClick}
        disabled={disabled}
        aria-label={ariaLabel}
        className={`color-${color} size-${size} spacing-${spacing} ${className}`}
      >
        {icon && getIcon(icon)} {children}
      </StyledButton>
    );
  }
  return (
    <StyledIconButton
      onClick={onClick}
      aria-label={ariaLabel || icon}
      disabled={disabled}
      className={`color-${color} size-${size} ${className}`}
    >
      {getIcon(icon)}
    </StyledIconButton>
  );
};

export default Button;
