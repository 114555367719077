import { useTranslation } from "react-i18next";
import { Label } from "../../../../../library/label";
import { Select } from "../../../../../library/select";
import { languages } from "../languages";
import {
  Field,
  SelectionWrapper,
  SmallText,
  ButtonsWrapper,
  FullRowWrapper,
  DisplayValue,
  SliderWrapper,
} from "./styled";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import Button from "../../../../../library/button";
import { UseFormSetValue } from "react-hook-form";
import { UploadTranscriptSchema } from "../schema";
import Slider from "../../../../../library/slider";

interface SettingsViewProps {
  setStep: Dispatch<SetStateAction<2 | 1 | 3 | 4>>;
  setValue: UseFormSetValue<UploadTranscriptSchema>;
  selectedLanguage: string;
  temperature: number;
}

const SettingsView: React.FC<SettingsViewProps> = ({
  setStep,
  setValue,
  selectedLanguage,
  temperature,
}) => {
  const { t } = useTranslation();

  const handleSelection = (language: string) => {
    setValue("language", language);
  };

  const clickBack = () => {
    setStep(1);
  };

  const clickNext = () => {
    setStep(3);
  };

  const renderTemperature = () => {
    return temperature ?? 0;
  };

  const onChangeSlide = (value: number | number[]) => {
    const val = value as number;
    setValue("settings.temperature", val);
  };

  return (
    <SelectionWrapper>
      <Field>
        <Label htmlFor="formatSelect">
          {t(
            "dashboard.pages.transcripts.add-modal.content.settings-step.input.language.label"
          )}
        </Label>
        <Select
          items={languages}
          onChange={(v: ChangeEvent<HTMLSelectElement>) =>
            handleSelection(v.target.value)
          }
          selected={selectedLanguage}
          id="formatSelect"
          ariaLabel={
            t(
              "dashboard.pages.transcripts.add-modal.content.settings-step.input.language.input-field.ariaLabel"
            ) as string
          }
        />
        <SmallText>
          {t(
            "dashboard.pages.transcripts.add-modal.content.settings-step.input.language.small-text"
          )}
        </SmallText>
      </Field>
      <FullRowWrapper>
        <Label>
          {" "}
          {t(
            "dashboard.pages.transcripts.add-modal.content.settings-step.input.temperature.label"
          )}
        </Label>
        <DisplayValue active={temperature > 0}>
          {temperature > 0
            ? `${temperature * 10}%`
            : t(
                "dashboard.pages.transcripts.add-modal.content.settings-step.input.temperature.none"
              )}
        </DisplayValue>
      </FullRowWrapper>
      <SliderWrapper>
        <Slider
          min={0}
          max={10}
          value={renderTemperature()}
          onChange={onChangeSlide}
        />
      </SliderWrapper>
      <SmallText>
        {t(
          "dashboard.pages.transcripts.add-modal.content.settings-step.input.temperature.small-text"
        )}
      </SmallText>
      <ButtonsWrapper>
        <Button
          ariaLabel={
            t(
              "dashboard.pages.transcripts.add-modal.content.settings-step.buttons.back.ariaLabel"
            ) as string
          }
          onClick={clickBack}
          variant="regular"
          color="grey"
        >
          {t(
            "dashboard.pages.transcripts.add-modal.content.settings-step.buttons.back.title"
          )}
        </Button>
        <Button
          type="submit"
          variant="regular"
          color="blue"
          onClick={clickNext}
          ariaLabel={
            t(
              "dashboard.pages.transcripts.add-modal.content.settings-step.buttons.next.ariaLabel"
            ) as string
          }
        >
          {t(
            "dashboard.pages.transcripts.add-modal.content.settings-step.buttons.next.title"
          )}
        </Button>
      </ButtonsWrapper>
    </SelectionWrapper>
  );
};

export default SettingsView;
