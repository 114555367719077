export const isFileTypeAllowed = (file: File): boolean => {
  const allowedFormats = [
    ".m4a",
    ".mp3",
    ".webm",
    ".mp4",
    ".mpga",
    ".wav",
    ".mpeg",
    ".ogg",
    ".heic",
    ".mov",
    ".avi",
  ];

  const fileExtension = `.${file.name.split(".").pop()}`;

  return allowedFormats.some(
    (format) => format === fileExtension.toLowerCase()
  );
};
