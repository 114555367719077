import styled from "@emotion/styled";

export const Container = styled.div`
  background-color: #f7fafc;
`;

export const InnerWrapper = styled.div`
  max-width: 78.125rem;
  margin: 0 auto;
  padding: 4.6875rem 1.25rem;
  @media (max-width: 900px) {
    padding-top: 2rem;
    padding-bottom: 4.6875rem;
  }
`;

export const DetailTitle = styled.span`
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #2f8de4;
`;

export const Section = styled.div<{ bottom: boolean }>`
  position: relative;
  display: flex;
  gap: 100px;
  align-items: center;
  padding-bottom: 175px;

  ${({ bottom }) =>
    bottom &&
    `
  display: block; 
  text-align: center;
  padding-bottom: 0rem;
  button{
    margin-top: 1.25rem;
  }
  `}

  @media(max-width: 900px) {
    flex-direction: column;
    padding-bottom: 100px;
    ${({ bottom }) =>
      bottom &&
      `
      padding-bottom: 0rem;
    `}
  }
`;

export const TextWrapper = styled.div`
  flex: 1;
  a {
    color: #2f8de4;
    font-weight: 600;
    text-decoration: none;
  }
  @media (max-width: 900px) {
    order: 2;
    margin-top: -50px;
  }
`;

export const DescriptionWrapper = styled.div`
  display: block;
  max-width: 48.75rem;
  margin: 0 auto;
  margin-top: 0.625rem;
`;

export const IllustrationWrapper = styled.div`
  flex: 1;
  @media (max-width: 900px) {
    display: block;
    width: 100%;
    max-width: 500px;
    order: 1;
  }
`;

export const Illustration = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
`;

export const OrderedList = styled.ol`
  margin-top: 15px;
`;

export const UnorderedList = styled.ul`
  margin-left: 0px !important;
`;

export const ListItem = styled.li`
  font-family: "Poppins", sans-serif;
  color: grey;
  line-height: 1.7rem;
  font-size: 1rem;
  margin-top: 0.1563rem;
  margin-bottom: 0.4688rem;
  a {
    color: #2f8de4;
  }
  ul {
    padding-bottom: 20px;
  }
`;

export const Bold = styled.span`
  font-weight: 600;
  color: #3b3b3b;
`;

export const IllustrationArrow = styled.img`
  position: absolute;
  left: 35%;
  bottom: 50px;
  width: 100%;
  max-width: 150px;

  @media (max-width: 954px) {
    display: none;
  }
`;

export const BottomTextWrapper = styled.div`
  max-width: 650px;
  margin: 0 auto;
  display: block;
`;
