import { useEffect } from "react";
import { BrowserRouter, Routes as AllRoutes, Route } from "react-router-dom";
import Index from "./pages/index";
import Login from "./pages/login";
import DashboardHistory from "./pages/dashboard/transcripts";
import DashboardTranscript from "./pages/dashboard/transcript";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "./firebase";
import Account from "./pages/dashboard/account";
import Register from "./pages/register";
import Validate from "./pages/validate";
import PrivacyPolicy from "./pages/privacy-policy";
import Cookies from "./pages/cookies";
import Contact from "./pages/contact";
import TermsOfUse from "./pages/terms-of-use";
import Blog from "./pages/blog";
import Article from "./pages/article";
import Blogposts from "./pages/dashboard/blogposts";
import Blogpost from "./pages/dashboard/edit-blogpost";
import NewBlogpost from "./pages/dashboard/new-blogpost";
import Workspace from "./pages/dashboard/workspace";
import useWorkspaces from "./hooks/useWorkspaces";
import useUser from "./hooks/useUser";
import NotFound from "./pages/not-found";
import ForgotPassword from "./pages/forgot-password";
import Pricing from "./pages/pricing";
import Webinar from "./pages/webinar";
import ThankYou from "./pages/thank-you";
import HelpCenter from "./pages/help-center";
import SupportedSoftware from "./pages/supported-software";
import AdobePremierePro from "./pages/adobe-premiere-pro";
import CamtasiaStudio from "./pages/camtasia-studio";
import BlenderStudio from "./pages/blender-studio";
import Kdenslive from "./pages/kdenslive";
import Avidemux from "./pages/avidemux";
import DaVinciResolve from "./pages/davinci-resolve";
import CyberlinkPowerDirector from "./pages/cyberlink-powerdirector";
import HitFilmPro from "./pages/hitfilm-pro";
import CorelVideoStudio from "./pages/corel-videostudio";
import Lightworks from "./pages/lightworks";
import AdobeAfterEffects from "./pages/adobe-after-effects";
import FinalCutPro from "./pages/final-cut-pro";
import VegasPro from "./pages/vegas-pro";
import Filmora from "./pages/filmora";
import Movavi from "./pages/movavi";
import DashboardSoundtracks from "./pages/dashboard/soundtracks";
import { useAppDispatch } from "./redux/hooks";
import PrivateRoute from "./components/PrivateRoute";

const Routes = () => {
  const dispatch = useAppDispatch();
  const auth = getAuth(firebaseApp);
  const { fetchWorkspaces } = useWorkspaces();
  const { fetchUserDetails } = useUser();

  //Clickjacking prevention
  useEffect(() => {
    if (!window?.top?.location) return;
    if (window.top !== window.self) {
      window.top.location = window.self.location;
    }
  }, []);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fetchWorkspaces();
        fetchUserDetails(user.uid);
      }
    });
  }, [dispatch, auth, fetchUserDetails, fetchWorkspaces]);

  return (
    <BrowserRouter>
      <AllRoutes>
        <Route path="/" element={<Index />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/validate/:id" element={<Validate />} />

        {/* Dashboard Routes */}
        <Route
          path="/dashboard/transcripts"
          element={
            <PrivateRoute>
              <DashboardHistory />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/soundtracks"
          element={
            <PrivateRoute>
              <DashboardSoundtracks />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/transcripts/:id"
          element={
            <PrivateRoute>
              <DashboardTranscript />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/account"
          element={
            <PrivateRoute>
              <Account />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/workspace"
          element={
            <PrivateRoute>
              <Workspace />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/admin/blogposts"
          element={
            <PrivateRoute>
              <Blogposts />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/admin/blogposts/:id"
          element={
            <PrivateRoute>
              <Blogpost />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/admin/blogpost/new"
          element={
            <PrivateRoute>
              <NewBlogpost />
            </PrivateRoute>
          }
        />

        {/* Public Routes */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/terms" element={<TermsOfUse />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/webinar" element={<Webinar />} />
        <Route path="/help-center" element={<HelpCenter />} />
        <Route
          path="/help-center/supported-software"
          element={<SupportedSoftware />}
        />
        <Route
          path="/help-center/supported-software/adobe-premiere-pro"
          element={<AdobePremierePro />}
        />
        <Route
          path="/help-center/supported-software/camtasia-studio"
          element={<CamtasiaStudio />}
        />
        <Route
          path="/help-center/supported-software/blender-studio"
          element={<BlenderStudio />}
        />
        <Route
          path="/help-center/supported-software/kdenslive"
          element={<Kdenslive />}
        />
        <Route
          path="/help-center/supported-software/avidemux"
          element={<Avidemux />}
        />
        <Route
          path="/help-center/supported-software/davinci-resolve"
          element={<DaVinciResolve />}
        />
        <Route
          path="/help-center/supported-software/cyberlink-powerdirector"
          element={<CyberlinkPowerDirector />}
        />
        <Route
          path="/help-center/supported-software/hitfilm-pro"
          element={<HitFilmPro />}
        />
        <Route
          path="/help-center/supported-software/corel-videostudio"
          element={<CorelVideoStudio />}
        />
        <Route
          path="/help-center/supported-software/lightworks"
          element={<Lightworks />}
        />
        <Route
          path="/help-center/supported-software/adobe-after-effects"
          element={<AdobeAfterEffects />}
        />
        <Route
          path="/help-center/supported-software/final-cut-pro"
          element={<FinalCutPro />}
        />
        <Route
          path="/help-center/supported-software/vegas-pro"
          element={<VegasPro />}
        />
        <Route
          path="/help-center/supported-software/filmora"
          element={<Filmora />}
        />
        <Route
          path="/help-center/supported-software/movavi"
          element={<Movavi />}
        />
        <Route path="/webinar/thank-you" element={<ThankYou />} />
        <Route path="/article/:id" element={<Article />} />
        <Route path="*" element={<NotFound />} />
      </AllRoutes>
    </BrowserRouter>
  );
};

export default Routes;
