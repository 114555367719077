import { LoadingContainer, LoadingText, Wrapper } from "./styled";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useTranslation } from "react-i18next";

interface UploadingViewProps {
  progress: number;
}

const UploadingView = ({ progress }: UploadingViewProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <LoadingContainer>
        <CircularProgressbar
          styles={buildStyles({ pathColor: "#2f8de4" })}
          value={progress}
          text={`${progress.toFixed(0)}%`}
        />
      </LoadingContainer>
      <LoadingText>
        {t(
          "dashboard.pages.transcripts.add-modal.content.select-source-step.uploading-container.progress-text"
        )}
        ..
      </LoadingText>
    </Wrapper>
  );
};

export default UploadingView;
