import { UploadForm } from "../styled";
import { useState } from "react";
import useUploadTranscript from "../../../../hooks/useUploadTranscript";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UploadTranscriptSchema, uploadTranscriptSchema } from "./schema";
import StepBar from "../../../../library/step-bar";
import { ContentWrapper } from "./styled";
import SettingsView from "./settings-view";
import SourceView from "./source-view";
import FormatView from "./format-view";
import { useDispatch } from "react-redux";
import {
  setTranscriptUploading,
  setUploadError,
} from "../../../../redux/slices/transcripts";
import { UploadError } from "../../../../types";

interface IAddModalProps {
  closeModal: () => void;
  dropFile: File | undefined;
}

const AddModal: React.FC<IAddModalProps> = ({ closeModal, dropFile }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);
  const { t } = useTranslation();
  const { uploadTranscript, progress } = useUploadTranscript(closeModal);

  const {
    watch,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<UploadTranscriptSchema>({
    resolver: zodResolver(uploadTranscriptSchema),
    defaultValues: {
      file: dropFile,
      language: "no",
      public: false,
      format: "long",
      duration: 0,
      settings: {
        temperature: 0,
      },
    },
  });

  const selectFormat = (value: string) => {
    setValue("format", value);
  };

  const selectedFile = watch("file");
  const selectedLanguage = watch("language");
  const selectedFormat = watch("format");
  const isFilePublic = watch("public");
  const temperature = watch("settings.temperature");

  const performUploadTranscript = async (data: UploadTranscriptSchema) => {
    try {
      await uploadTranscript(data);
    } catch (e) {
      const message = e as UploadError;
      closeModal();
      dispatch(setTranscriptUploading(false));
      dispatch(setUploadError(message));
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <SourceView
            setValue={setValue}
            selectedFile={selectedFile}
            isFilePublic={isFilePublic}
            setStep={setStep}
            errors={errors}
            onClose={closeModal}
            trigger={trigger}
          />
        );
      case 2:
        return (
          <SettingsView
            setStep={setStep}
            setValue={setValue}
            selectedLanguage={selectedLanguage}
            temperature={temperature}
          />
        );
      default:
        return (
          <FormatView
            setStep={setStep}
            setValue={setValue}
            selectedFormat={selectedFormat}
            setSelectionType={selectFormat}
            progress={progress}
          />
        );
    }
  };

  return (
    <UploadForm onSubmit={handleSubmit(performUploadTranscript)}>
      <StepBar
        currentStep={step}
        titles={[
          t("dashboard.pages.transcripts.add-modal.progress-bar.source-step"),
          t("dashboard.pages.transcripts.add-modal.progress-bar.settings-step"),
          t("dashboard.pages.transcripts.add-modal.progress-bar.format-step"),
        ]}
      />
      <ContentWrapper>{renderStep()}</ContentWrapper>
    </UploadForm>
  );
};

export default AddModal;
