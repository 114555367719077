import styled from "@emotion/styled";

export const PlayerWrapper = styled.div`
  width: 100%;
  height: auto;
  z-index: 1;
  padding: 1.5rem;
`;

export const VideoWrapper = styled.div<{ isFullscreen: boolean }>`
  position: relative;
  display: block;
  background-color: black;
  border-radius: 1rem;
  text-align: center;
  overflow: hidden;
  max-height: ${({ isFullscreen }) =>
    isFullscreen ? "calc(100vh - 50px - 78px - 78px)" : "auto"};
`;

export const Video = styled.video`
  display: block;
  object-fit: contain;
  background-color: #000;
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
  max-height: calc(100vh - 78px - 50px - 80px);
`;

export const SubtitleWrapper = styled.div<{ playerWidth: number }>`
  position: absolute;
  text-align: center;
  padding: 0px 20px;
  width: 100%;
  max-width: ${({ playerWidth }) => playerWidth}px;
  left: 50%;
  transform: translateX(-50%);
  overflow-x: hidden;

  &.vertical-top {
    top: 5%;
  }
  &.vertical-center {
    top: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
  }
  &.vertical-bottom {
    bottom: 5%;
  }
`;

export const ActiveSubtitle = styled.span<{
  fontFamily: string;
  fontSize: number;
  color: string;
  backgroundColor: string;
  decoration: string;
  fontStyle: string;
}>`
  position: relative;
  color: white;
  display: inline;
  line-height: 1.1;
  font-size: ${({ fontSize }) => fontSize}px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-family: ${({ fontFamily }) => fontFamily};
  text-decoration: ${({ decoration }) => decoration};
  font-style: ${({ fontStyle }) =>
    fontStyle === "italic" ? "italic" : "normal"};
  font-weight: ${({ fontStyle }) => (fontStyle === "bold" ? "bold" : "none")};
`;
