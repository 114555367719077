import {
  Container,
  InnerWrapper,
  Section,
  TextWrapper,
  IllustrationWrapper,
  OrderedList,
  ListItem,
  Bold,
  Illustration,
  IllustrationArrow,
  BottomTextWrapper,
} from "./styled";
import { Title } from "../../../library/title";
import { Text } from "../../../library/text";
import Button from "../../../library/button";
import illustrationArrow from "../../../assets/img/illustration_arrow.svg";
import uploadThumbnail from "../../../assets/img/upload-thumbnail.svg";
import importThumbnail from "../../../assets/img/import-thumbnail.svg";
import { Link } from "react-router-dom";

const Content = () => {
  return (
    <Container>
      <InnerWrapper>
        <Section bottom={false}>
          <TextWrapper>
            <Title>Steg 1 - transkriber med Randi</Title>
            <Text color="dark">
              Logg inn på Randi-brukeren din og trykk på "Transkripter" øverst i
              menyen og gjør deretter følgende:
            </Text>
            <OrderedList>
              <ListItem>
                Trykk på <Bold>Nytt transkript</Bold>-knappen øverst til høyre.
              </ListItem>
              <ListItem>
                Fra modalen som dukker opp, trykk på <Bold>Velg fil</Bold> eller
                dra og slipp ønsket fil inn i filvalg-boksen.
              </ListItem>
              <ListItem>
                Velg om filen skal være kun tilgjengelig for deg selv eller
                andre i arbeidsmiljøet ditt og trykk på <Bold>Gå videre</Bold>.
              </ListItem>
              <ListItem>
                Velg deretter språket du ønsker at underteksten din skal tekstes
                til og juster <Bold>Språklig frihet</Bold> etter behov. Trykk så{" "}
                <Bold>Gå videre</Bold>.
              </ListItem>
              <ListItem>
                Til slutt velger du hva slags format du ønsker at underteksten
                din skal ha (tekstlengde) og trykk til slutt på{" "}
                <Bold>Start transkribering</Bold>.
              </ListItem>
            </OrderedList>
            <Text color="dark">
              Trykk til slutt på <Bold>Last ned</Bold> og velg enten VTT eller
              SRT-format - Blender Studio støtter begge.
            </Text>
          </TextWrapper>
          <IllustrationWrapper>
            <Illustration src={uploadThumbnail} />
          </IllustrationWrapper>
          <IllustrationArrow src={illustrationArrow} />
        </Section>
        <Section bottom={false}>
          <IllustrationWrapper>
            <Illustration src={importThumbnail} />
          </IllustrationWrapper>
          <TextWrapper>
            <Title>Steg 2 - importer til Blender Studio</Title>
            <Text color="dark">
              For å importere undertekster må du først laste ned Subsimport fra
              Github{" "}
              <a
                href="https://github.com/doakey3/Subsimport"
                target="_blank"
                rel="noreferrer"
              >
                her
              </a>{" "}
              og deretter gjøre følgende:
            </Text>
            <OrderedList>
              <ListItem>
                Åpne <Bold>Blender</Bold>, gå til{" "}
                <Bold>
                  Edit {">"} Preferences {">"} Add-ons
                </Bold>
                , klikk på <Bold>Install</Bold>, velg zip-filen for Subsimport,
                og aktiver tillegget.
              </ListItem>
              <ListItem>
                Gå til <Bold>Video Editing</Bold>-arbeidsområdet og åpne{" "}
                <Bold>Video Sequence Editor (VSE)</Bold>.
              </ListItem>
              <ListItem>
                I høyre panel, finn <Bold>Subsimport</Bold>-seksjonen og klikk
                på <Bold>Import Subtitles</Bold>, velg undertekstfilen din
                (SRT/VTT), og klikk <Bold>Import</Bold>.
              </ListItem>
              <ListItem>
                Undertekstene legges til som tekststrips i tidslinjen. Du kan
                justere tekst, timing og stil i <Bold>Properties</Bold>-panelet
                etter behov.
              </ListItem>
              <ListItem>
                Når du er fornøyd med resultatet, gå til{" "}
                <Bold>
                  File {">"} Export {">"} Render Animation
                </Bold>{" "}
                for å eksportere videoen med undertekster.
              </ListItem>
            </OrderedList>
          </TextWrapper>
        </Section>
        <Section bottom={true}>
          <Title>Tekst din video gratis med Randi</Title>
          <BottomTextWrapper>
            <Text color="dark">
              Kom i gang med automatisk teksting av video helt gratis og få opp
              til 2 opplastinger hver måned på videoer opp til 20 minutter.
            </Text>
          </BottomTextWrapper>
          <Link to="/register">
            <Button color="blue" variant="regular">
              Registrer gratis bruker
            </Button>
          </Link>
        </Section>
      </InnerWrapper>
    </Container>
  );
};

export default Content;
