export const SUBSCRIPTION_TYPES = [
  {
    id: "small",
    title: "register.views.select-subscription.options.small-option.title",
    description:
      "register.views.select-subscription.options.small-option.description",
    price: "register.views.select-subscription.options.small-option.price",
    duration:
      "register.views.select-subscription.options.small-option.duration",
    ariaLabel:
      "register.views.select-subscription.options.small-option.ariaLabel",
    variant: "small",
  },
  {
    id: "social-monthly",
    title: "register.views.select-subscription.options.social-option.title",
    description:
      "register.views.select-subscription.options.social-option.description",
    price:
      "register.views.select-subscription.options.social-option.monthly.price",
    duration:
      "register.views.select-subscription.options.social-option.monthly.duration",
    ariaLabel:
      "register.views.select-subscription.options.social-option.monthly.ariaLabel",
    variant: "monthly",
  },
  {
    id: "social-annually",
    title: "register.views.select-subscription.options.social-option.title",
    description:
      "register.views.select-subscription.options.social-option.description",
    price:
      "register.views.select-subscription.options.social-option.annually.price",
    duration:
      "register.views.select-subscription.options.social-option.annually.duration",
    ariaLabel:
      "register.views.select-subscription.options.social-option.annually.ariaLabel",
    variant: "annually",
  },
  {
    id: "go-monthly",
    title: "register.views.select-subscription.options.go-option.title",
    description:
      "register.views.select-subscription.options.go-option.description",
    price: "register.views.select-subscription.options.go-option.monthly.price",
    duration:
      "register.views.select-subscription.options.go-option.monthly.duration",
    ariaLabel:
      "register.views.select-subscription.options.go-option.monthly.ariaLabel",
    variant: "monthly",
  },
  {
    id: "go-annually",
    title: "register.views.select-subscription.options.go-option.title",
    description:
      "register.views.select-subscription.options.go-option.description",
    price:
      "register.views.select-subscription.options.go-option.annually.price",
    duration:
      "register.views.select-subscription.options.go-option.annually.duration",
    ariaLabel:
      "register.views.select-subscription.options.go-option.annually.ariaLabel",
    variant: "annually",
  },
];

export const subscriptionRules = {
  small: {
    actions: {
      "speech-to-text": {
        countLimit: 2,
        durationLimit: 1200,
        message: {
          durationLimit: {
            title: "Videoen er for lang",
            body: "Videoene du laster opp kan ikke være lengre enn 20 minutter på gratisabonnementet. Oppgrader til Randi Go for å laste opp lengre videoer.",
          },
          countLimit: {
            title: "Månedlig grense nådd",
            body: "Du kan kun laste opp 2 videoer i måneden med Randi Small-abonnementet. Oppgrader til Randi Social eller Randi Go for å laste opp flere videoer.",
          },
        },
      },
      "text-to-speech": {
        countLimit: 2,
        textLimit: 100,
        message: {
          textLimit: {
            title: "Tekstlengde er for lang",
            body: "Tekstlengden du laster opp kan ikke være lengre enn 100 minutter på gratisabonnementet. Oppgrader til Randi Go for å laste opp lengre videoer.",
          },
          countLimit: {
            title: "Månedlig grense nådd",
            body: "Lydklippene du lager kan ikke være lengre enn 1 minutt på Randi-social abonnementet. Oppgrader til Randi Go for å lage lengre lydklipp.",
          },
        },
      },
      "download-video": {
        watermark: true,
        message: {
          title: "Synlig vannmerke",
          body: "Videoene du laster ned vil ha vannmerke med Randi-logo.",
        },
      },
    },
  },
  social: {
    actions: {
      "speech-to-text": {
        countLimit: null,
        durationLimit: 60,
        message: {
          durationLimit: {
            title: "Videoen er for lang",
            body: "Videoene du laster opp kan ikke være lengre enn 1 minutt på Randi Social-abonnementet. Oppgrader til Randi Go for å laste opp lengre videoer.",
          },
          countLimit: null,
        },
      },
      "text-to-speech": {
        countLimit: null,
        textLimit: 100,
        message: {
          textLimit: {
            title: "Teksten er for lang",
            body: "Lydklippene du lager kan ikke være lengre enn 1 minutt på Randi-social abonnementet. Oppgrader til Randi Go for å lage lengre lydklipp.",
          },
          countLimit: null,
        },
      },
      "download-video": {
        watermark: false,
      },
    },
  },
  go: {
    actions: {
      "speech-to-text": {
        countLimit: null,
        durationLimit: null,
      },
      "text-to-speech": {
        countLimit: null,
        textLimit: null,
      },
      "download-video": {
        watermark: false,
      },
    },
  },
};
