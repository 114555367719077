import { Helmet } from "react-helmet";

const Meta = () => {
  return (
    <Helmet>
      <title>Randi - Hjelpesenter</title>
      <meta name="thumbnail" content="https://randi.ai/showcase.jpg" />
      <meta name="robots" content="index, follow" />
      <meta
        name="description"
        content="Velkommen til hjelpesenteret vårt. Her finner du en oversikt over hvordan du tar i bruk Randi og hvordan du kan bruke det med andre redigeringsverktøy."
      />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content="https://randi.ai/help-center" />
      <meta name="twitter:title" content="Randi - Hjelpesenter" />
      <meta
        name="twitter:description"
        content="Velkommen til hjelpesenteret vårt. Her finner du en oversikt over hvordan du tar i bruk Randi og hvordan du kan bruke det med andre redigeringsverktøy."
      />
      <meta name="twitter:image" content="https://randi.ai/showcase.jpg" />

      <meta property="og:url" content="https://randi.ai/help-center" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Randi - Hjelpesenter" />
      <meta
        property="og:description"
        content="Velkommen til hjelpesenteret vårt. Her finner du en oversikt over hvordan du tar i bruk Randi og hvordan du kan bruke det med andre redigeringsverktøy."
      />
      <meta property="og:image" content="https://randi.ai/showcase.jpg" />
      <link rel="canonical" href="https://randi.ai/help-center/" />
    </Helmet>
  );
};

export default Meta;
