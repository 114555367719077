export const FEATURE_LIST = [
  {
    title: "Tilgang til video-editor",
    availableIn: ["Randi Small", "Randi Social", "Randi Go"],
  },
  {
    title: "Laste ned tale til tekst (srt, vtt, html og txt)",
    availableIn: ["Randi Small", "Randi Social", "Randi Go"],
  },
  {
    title: "Laste ned tekst til tale (ogg og mp4)",
    availableIn: ["Randi Small", "Randi Social", "Randi Go"],
  },
  {
    title: "Ingen vannmerke på video",
    availableIn: ["Randi Social", "Randi Go"],
  },
  {
    title: "Ubegrenset antall tale til tekst",
    availableIn: ["Randi Social", "Randi Go"],
  },
  {
    title: "Opplasting av lyd/video over 20 minutter",
    availableIn: ["Randi Go"],
  },
  {
    title: "Ubegrenset antall tekst til tale",
    availableIn: ["Randi Go"],
  },
  {
    title: "Laste ned transkripter (.pdf, .csv og .docx)",
    availableIn: ["Randi Go"],
  },
  {
    title: "Ubegrenset varighet på tekstet video (>1 minutt)",
    availableIn: ["Randi Go"],
  },
];

export const SUBSCRIPTION_TIERS = [
  {
    title: "Randi Small",
    prices: ["Gratis", "Gratis"],
  },
  {
    title: "Randi Social",
    prices: [149, 1699],
  },
  {
    title: "Randi Go",
    prices: [349, 3999],
  },
];
