import styled from "@emotion/styled";

export const Section = styled.section`
  position: relative;
  background-color: #f6f9fb;
`;

export const Container = styled.div`
  max-width: 78.125rem;
  margin: 0 auto;
  padding: 4.6875rem 1.25rem;
`;

export const TitleWrapper = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
`;

export const SelectionWrapper = styled.div`
  margin-top: 100px;
`;

export const TabsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(160px, 1fr)
  ); /* Default: Flexible layout */
  gap: 20px;

  text-align: center;
  width: 100%;
  max-width: 800px;
  @media (max-width: 900px) {
    gap: 40px;
    grid-template-columns: repeat(2, auto);
    width: 100%;
  }
`;

export const Tab = styled.button`
  position: relative;
  max-width: 160px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  background: none;
  color: #3b3b3b;
  text-align: left;
`;

export const ShowcaseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
  margin: 0 auto;
  padding: 40px 20px;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const IllustrationWrapper = styled.div`
  flex: 1;
  max-width: 500px;
  @media (max-width: 900px) {
    order: 1;
  }
`;

export const Illustration = styled.img<{ customHeight: boolean }>`
  width: 100%;
  height: auto;
  margin-top: ${({ customHeight }) => (customHeight ? "82.5px" : "auto")};
  margin-bottom: ${({ customHeight }) => (customHeight ? "82.5px" : "auto")};
`;

export const DetailsWrapper = styled.div`
  flex: 1;
  a {
    text-decoration: none;
  }
  button {
    display: block;
    margin-top: 1.5625rem;
  }
  @media (max-width: 900px) {
    order: 2;
  }
`;

export const SelectedIndicator = styled.img`
  width: 100%;
  position: absolute;
  left: -10px;
  right: 0px;
  bottom: -15px;
`;

export const TextWrapper = styled.div`
  margin-top: 20px;
`;

export const IllustrationArrow = styled.img`
  position: absolute;
  left: 35%;
  bottom: 0;
  width: 100%;
  max-width: 200px;

  @media (max-width: 650px) {
    bottom: -70px;
    max-width: 150px;
  }
`;
