import Button from "../../../../../library/button";
import InputFields from "./input-fields";
import RecordView from "./record-view";
import { ButtonsWrapper } from "./styled";
import UploadView from "./upload-view";
import { useTranslation } from "react-i18next";
import ToggleButtons from "../../../../../library/toggle-buttons";
import { FieldErrors, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import { UploadTranscriptSchema } from "../schema";
import { Dispatch, SetStateAction, useState } from "react";

interface SourceViewProps {
  setValue: UseFormSetValue<UploadTranscriptSchema>;
  selectedFile: File | undefined;
  errors: FieldErrors;
  isFilePublic: boolean;
  setStep: Dispatch<SetStateAction<1 | 2 | 3 | 4>>;
  onClose: () => void;
  trigger: UseFormTrigger<UploadTranscriptSchema>;
}

const SourceView = ({
  setValue,
  selectedFile,
  errors,
  isFilePublic,
  setStep,
  onClose,
  trigger,
}: SourceViewProps) => {
  const { t } = useTranslation();

  const [selectionType, setSelectionType] = useState<"file" | "recording">(
    "file"
  );

  const clickCancel = () => {
    onClose();
  };

  const clickGoNext = async () => {
    const fileExists = await trigger("file");
    if (fileExists) setStep(2);
  };

  const selectTab = (value: "file" | "recording") => {
    setSelectionType(value);
  };

  return (
    <div>
      <ToggleButtons
        onSelect={selectTab}
        options={[
          {
            title: t(
              "dashboard.pages.transcripts.add-modal.content.select-source-step.toggle-buttons.file-toggle"
            ),
            value: "file",
          },
          {
            title: t(
              "dashboard.pages.transcripts.add-modal.content.select-source-step.toggle-buttons.audio-toggle"
            ),
            value: "recording",
          },
        ]}
        selected={selectionType}
      />
      {selectionType === "recording" ? (
        <RecordView
          setValue={setValue}
          selectedFile={selectedFile}
          errors={errors}
        />
      ) : (
        <UploadView
          setValue={setValue}
          selectedFile={selectedFile as any}
          errors={errors}
        />
      )}

      <InputFields setValue={setValue} isFilePublic={isFilePublic} />
      <ButtonsWrapper>
        <Button
          onClick={clickCancel}
          variant="regular"
          color="grey"
          ariaLabel={
            t(
              "dashboard.pages.transcripts.add-modal.content.select-source-step.buttons.cancel.ariaLabel"
            ) as string
          }
        >
          {t(
            "dashboard.pages.transcripts.add-modal.content.select-source-step.buttons.cancel.title"
          )}
        </Button>
        <Button
          type="submit"
          onClick={clickGoNext}
          variant="regular"
          color="blue"
          ariaLabel={
            t(
              "dashboard.pages.transcripts.add-modal.content.select-source-step.buttons.next.ariaLabel"
            ) as string
          }
        >
          {t(
            "dashboard.pages.transcripts.add-modal.content.select-source-step.buttons.next.title"
          )}
        </Button>
      </ButtonsWrapper>
    </div>
  );
};

export default SourceView;
