import moment from "moment";
import {
  TableData,
  TableRow as FullTableRow,
  Value,
  ButtonsWrapper,
  ProgressWrapper,
  Status,
  ProgressBarWrapper,
} from "./styled";
import Checkbox from "../../../../../library/checkbox";
import {
  appendDeletionArray,
  removeTranscript,
  setTranscriptStatus,
  setUploadError,
  subtractDeletionArray,
} from "../../../../../redux/slices/transcripts";
import { useEffect, useState } from "react";
import useMonitorTranscript from "../../../../../hooks/useMonitorTranscript";
import ExportDetails from "../../../../../components/ExportModal";
import Button from "../../../../../library/button";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../../library/notification";
import useDeleteTranscript from "../../../../../hooks/useDeleteTranscript";
import useListTranscripts from "../../../../../hooks/useTranscriptsList";
import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";
import { TranscriptRecordItem } from "../../../../../types";
import { Link } from "react-router-dom";
import ProgressBar from "../../../../../library/progress-bar";
import Modal from "../../../../../library/modal";
import Confirmation from "../../../../../library/modal/confirmation";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";

const MOBILE_VIEW = 800;

interface ITableRowProps {
  data: TranscriptRecordItem;
  arr: any;
  index: number;
}

const TableRow: React.FC<ITableRowProps> = ({ data }) => {
  const pollInterval = 5000;

  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [status, setStatus] = useState<string | null>(data.status);
  const [progression, setProgression] = useState<number>(data.progress);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const openExportModal = () => {
    setShowExportModal(true);
  };

  const closeExportModal = () => {
    setShowExportModal(false);
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const { t } = useTranslation();
  const { deleteTranscript } = useDeleteTranscript();
  const { fetchTranscriptsList } = useListTranscripts();
  const { monitorTranscript } = useMonitorTranscript();

  const deletions = useAppSelector<string[]>(
    (state) => state.transcripts.deletionArray
  );

  useEffect(() => {
    let isPolling = true;

    const startPolling = async () => {
      if (status === "completed") {
        setInProgress(false);
        return;
      }

      while (isPolling) {
        try {
          const response = await monitorTranscript(data?.id);

          if (response?.message) {
            dispatch(setUploadError(response.message));
          }

          if (response?.progress) {
            setProgression(response.progress);
          }

          if (response?.status) {
            setStatus(response.status);
          }

          if (response?.contentRef) {
            dispatch(
              setTranscriptStatus({
                id: data?.id,
                status: response.status,
                contentRef: response.contentRef,
              })
            );
          }

          if (response?.status === "notification" && response?.id) {
            isPolling = false;

            setStatus("notification");
            dispatch(
              removeTranscript({
                id: response?.id,
                isPublic: data.public,
              })
            );
            setInProgress(false);

            break;
          }

          await new Promise((resolve) => setTimeout(resolve, pollInterval));
        } catch (error) {
          isPolling = false;
        }
      }
    };

    startPolling();

    return () => {
      isPolling = false;
    };
  }, [
    data?.id,
    data.public,
    dispatch,
    monitorTranscript,
    pollInterval,
    status,
  ]);

  const handleSingleSelection = (e: any, id: string) => {
    if (e.target.checked) {
      if (!deletions.includes(id)) {
        dispatch(appendDeletionArray(id));
      }
    } else {
      if (deletions.includes(id)) {
        dispatch(subtractDeletionArray(id));
      }
    }
  };

  const renderDisplayColor = () => {
    switch (status) {
      case "completed":
        return "completed";
      case "failed":
        return "failed";
      default:
        return "progress";
    }
  };

  const renderPercentage = () => {
    return progression.toFixed(0) ?? 0;
  };

  const renderProgress = () => {
    switch (status) {
      case "completed":
        return t("dashboard.pages.transcripts.table.data.statuses.completed");
      case "failed":
        return t("dashboard.pages.transcripts.table.data.statuses.failed");
      default:
        return (
          <ProgressBarWrapper>
            <ProgressBar
              className="full-progress-bar"
              label={`${renderPercentage()}%`}
              percentage={progression}
              size="md"
              color="progress"
            />
          </ProgressBarWrapper>
        );
    }
  };

  const clickDeleteTranscript = async () => {
    try {
      const deletion = await deleteTranscript(data?.id);
      if (deletion.error === null) {
        sendSuccessNotification(
          t(
            "dashboard.universal.notifications.transcripts.delete-single.success"
          )
        );
        closeDeleteModal();
        fetchTranscriptsList();
      }
    } catch (e) {
      sendErrorNotification(
        t("dashboard.universal.notifications.transcripts.delete-single.failed")
      );
    }
  };

  const canEditTranscript = (): boolean => {
    if (data?.content) {
      return Array.isArray(data.content);
    }
    return data?.contentRef ? true : false;
  };

  const renderInProgress = () => {
    return !(status === "completed" || status === "failed");
  };

  return (
    <FullTableRow>
      <TableData className="select">
        <Checkbox
          onChange={(e: boolean) => handleSingleSelection(e, data?.id)}
          checked={deletions.includes(data?.id) ? true : false}
          name="product-select"
          ariaLabel={t("dashboard.universal.tables.select.ariaLabel") as string}
        />
      </TableData>
      <TableData>
        {canEditTranscript() ? (
          <Link to={`/dashboard/transcripts/${data?.id}`}>
            <Value className="bold title">
              {renderInProgress() && (
                <ProgressWrapper>
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={true}
                  />
                </ProgressWrapper>
              )}
              {data?.filename
                ? data?.filename
                : t("dashboard.universal.text.not-available")}
            </Value>
          </Link>
        ) : (
          <Value className="bold title">
            {renderInProgress() && (
              <ProgressWrapper>
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              </ProgressWrapper>
            )}
            {data?.filename
              ? data?.filename
              : t("dashboard.universal.text.not-available")}
          </Value>
        )}
      </TableData>
      <TableData className="date-display">
        <Value>{moment.unix(data?.timestamp).format("HH:mm DD/MM/YYYY")}</Value>
      </TableData>
      <TableData>
        <Value>
          <Status className={`${renderDisplayColor()}`}>
            {renderProgress()}
          </Status>
        </Value>
      </TableData>
      <TableData className="buttons">
        <ButtonsWrapper>
          {status === "completed" && (
            <>
              <Button
                variant={isMobile ? "icon" : "regular"}
                icon="download"
                color="grey"
                size="md"
                onClick={openExportModal}
                ariaLabel={
                  t(
                    "dashboard.pages.transcripts.table.data.actions.download.ariaLabel"
                  ) as string
                }
              >
                {t(
                  "dashboard.pages.transcripts.table.data.actions.download.title"
                )}
              </Button>
              <Link to={`/dashboard/transcripts/${data?.id}`}>
                <Button size="md" color="blue" variant="icon" icon="editor" />
              </Link>
            </>
          )}

          <Button
            size="md"
            color="grey"
            variant="icon"
            icon="trash"
            disabled={inProgress}
            onClick={openDeleteModal}
            ariaLabel={
              t(
                "dashboard.pages.transcripts.table.data.actions.delete.ariaLabel"
              ) as string
            }
          />
        </ButtonsWrapper>
      </TableData>
      <Confirmation
        open={showDeleteModal}
        onClose={closeDeleteModal}
        title={t("dashboard.pages.transcripts.delete-single-modal.title")}
        description={t("dashboard.pages.transcripts.delete-single-modal.text")}
        variant="delete"
        onSubmit={clickDeleteTranscript}
        submitText={
          t(
            "dashboard.pages.transcripts.delete-single-modal.buttons.delete.title"
          )!
        }
      />
      <Modal
        onClose={closeExportModal}
        open={showExportModal}
        title="Last ned transkript"
      >
        <ExportDetails
          onClose={closeExportModal}
          transcriptId={data?.id}
          fileName={data?.filename}
        />
      </Modal>
    </FullTableRow>
  );
};

export default TableRow;
