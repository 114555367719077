import {
  HeaderWrapper,
  InnerContainer,
  TextWrapper,
  TitleWrapper,
} from "./styled";
import { Text } from "../../../library/text";
import { Title } from "../../../library/title";

const Header = () => {
  return (
    <HeaderWrapper>
      <InnerContainer>
        <TitleWrapper>
          <Title size="xl">Støttede programmer</Title>
        </TitleWrapper>
        <TextWrapper>
          <Text color="dark">
            Nedenfor finner du en liste over alle programmer og
            videoredigeringstjenester som støtter automatisk teksting med Randi
            for en enklere arbeidsflyt.
          </Text>
        </TextWrapper>
      </InnerContainer>
    </HeaderWrapper>
  );
};

export default Header;
