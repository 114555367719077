import { Text } from "../../../library/text";
import {
  Container,
  DetailsWrapper,
  Illustration,
  IllustrationArrow,
  IllustrationWrapper,
  Section,
  SelectedIndicator,
  SelectionWrapper,
  ShowcaseWrapper,
  Tab,
  TabsWrapper,
  TextWrapper,
  TitleWrapper,
} from "./styled";
import { Title } from "../../../library/title";
import Button from "../../../library/button";
import tabSelected from "../../../assets/img/tab_selected.svg";
import illustrationArrow from "../../../assets/img/illustration_arrow.svg";
import someIllustration from "../../../assets/img/some_illustration.svg";
import podcastIllustration from "../../../assets/img/podcast_illustration.svg";
import tvIllustration from "../../../assets/img/tv_illustration.svg";
import companyVideoIllustration from "../../../assets/img/company_video.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();

  const USECASES = [
    {
      selection: t(
        "frontpage.sections.usecases-view.selections.first-selection.selection"
      ),
      title: t(
        "frontpage.sections.usecases-view.selections.first-selection.title"
      ),
      description: t(
        "frontpage.sections.usecases-view.selections.first-selection.text"
      ),
      illustration: someIllustration,
    },
    {
      selection: t(
        "frontpage.sections.usecases-view.selections.second-selection.selection"
      ),
      title: t(
        "frontpage.sections.usecases-view.selections.second-selection.title"
      ),
      description: t(
        "frontpage.sections.usecases-view.selections.second-selection.text"
      ),
      illustration: podcastIllustration,
    },
    {
      selection: t(
        "frontpage.sections.usecases-view.selections.third-selection.selection"
      ),
      title: t(
        "frontpage.sections.usecases-view.selections.third-selection.title"
      ),
      description: t(
        "frontpage.sections.usecases-view.selections.third-selection.text"
      ),
      illustration: tvIllustration,
    },
    {
      selection: t(
        "frontpage.sections.usecases-view.selections.fourth-selection.selection"
      ),
      title: t(
        "frontpage.sections.usecases-view.selections.fourth-selection.title"
      ),
      description: t(
        "frontpage.sections.usecases-view.selections.fourth-selection.text"
      ),
      illustration: companyVideoIllustration,
    },
  ];

  const [display, setDisplay] = useState(USECASES[0]);

  const handleTabChange = (tab: any) => {
    setDisplay(tab);
  };

  return (
    <Section>
      <Container>
        <TitleWrapper>
          <Title>{t("frontpage.sections.usecases-view.title")}</Title>
          <Text color="dark">{t("frontpage.sections.usecases-view.text")}</Text>
        </TitleWrapper>
        <SelectionWrapper>
          <TabsWrapper>
            {USECASES.map((usecase) => (
              <Tab
                onClick={() => handleTabChange(usecase)}
                key={usecase.selection}
              >
                {usecase.selection}
                {usecase.selection === display.selection && (
                  <SelectedIndicator src={tabSelected} />
                )}
              </Tab>
            ))}
          </TabsWrapper>
          <ShowcaseWrapper>
            <DetailsWrapper>
              <Title>{display.title}</Title>
              <TextWrapper>
                <Text color="dark">{display.description}</Text>
              </TextWrapper>
              <Link to="/register">
                <Button variant="regular" color="blue">
                  {t("frontpage.sections.usecases-view.button")}
                </Button>
              </Link>
            </DetailsWrapper>
            <IllustrationWrapper>
              <Illustration
                customHeight={
                  display.selection ===
                  t(
                    "frontpage.sections.usecases-view.selections.third-selection.selection"
                  )
                }
                src={display.illustration}
              />
            </IllustrationWrapper>
          </ShowcaseWrapper>
        </SelectionWrapper>
        <IllustrationArrow src={illustrationArrow} />
      </Container>
    </Section>
  );
};

export default Index;
