import { Link } from "react-router-dom";
import { BoxContent, BoxName, BoxWrapper, Thumbnail } from "./styled";

interface ITestimonialBoxProps {
  thumbnail: string;
  name: string;
  url: string;
}

const TestimonialBox: React.FC<ITestimonialBoxProps> = ({
  thumbnail,
  name,
  url,
}) => {
  return (
    <BoxWrapper>
      <Link to={url}>
        <BoxContent>
          <Thumbnail alt={name} src={thumbnail} />
          <BoxName>{name}</BoxName>
        </BoxContent>
      </Link>
    </BoxWrapper>
  );
};

export default TestimonialBox;
