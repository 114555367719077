import styled from "@emotion/styled";

export const Section = styled.section`
  background-color: #f6f9fb;
`;

export const Container = styled.div`
  max-width: 78.125rem;
  margin: 0 auto;
  padding: 4.6875rem 1.25rem;
`;

export const TitleWrapper = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
`;

export const LogoContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const LogoWrapper = styled.div`
  margin-top: 40px;
  padding: 0px 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 767px) {
    gap: 25px;
  }
`;

export const Logo = styled.img`
  max-width: 110px;
  max-height: 60px;
  margin: 0 auto;
  opacity: 0.5;
  transition: 0.5s;
  &.shrink {
    padding: 5px;
  }
  &:hover {
    opacity: 1;
  }
  @media (max-width: 767px) {
    max-width: 90px;
  }
`;

export const BottomWrapper = styled.div`
  margin-top: 125px;
`;

export const FeaturesWrapper = styled.section`
  display: flex;
  text-align: left;
  justify-content: center;
  flex-grow: 1;
  flex-wrap: wrap; /* Ensures responsiveness */
  gap: 30px; /* Adds spacing between items */
  padding: 40px 20px;
  margin: 0 auto;
`;

export const FeatureCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  flex: 1 1 calc(33.33% - 20px); /* Allows it to shrink and respect 3-column layout */
  min-width: 280px;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
  margin-top: 50px;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 980px) {
    flex: 1 1 calc(100% - 20px);
    margin-top: 20px;
    max-width: 100%;
  }
`;

export const IconWrapper = styled.div`
  font-size: 30px;
  margin-bottom: 15px;
  color: #2f8de4;
  font-size: 42px;
`;
