import { useEffect, useState } from "react";
import Button from "../../library/button";
import { Label } from "../../library/label";
import { getIcon } from "../../utils/get-icon";
import {
  PriceDetailsCol,
  PriceDisplayContainer,
  PriceDisplayRow,
  SelectButton,
  PlanTitle,
  PlanDescription,
  PriceTitle,
  BillingTitle,
  Field,
  ModalContentContainer,
  ModalButtonsContainer,
  Form,
  ToggleWrapper,
  DisclaimerText,
} from "./styled";
import Input from "../../library/input";
import { useSelector } from "react-redux";
import { sendErrorNotification } from "../../library/notification";
import useResubscribe from "../../hooks/useUpgradeSubscription";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { SubscriptionSchema, schema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import ToggleButtons from "../../library/toggle-buttons";
import { Link } from "react-router-dom";
import { IState } from "../../types";
import { SUBSCRIPTION_TYPES } from "../../utils/subscriptions";

interface ISubscriptionModalProps {
  onClose: () => void;
}

const SubscriptionModal: React.FC<ISubscriptionModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );

  const { upgradeSubscription } = useResubscribe();
  const [selectedDuration, setSelectedDuration] = useState<string>("monthly");

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<SubscriptionSchema>({
    resolver: zodResolver(schema),
    defaultValues: { licenseAmount: "1", subscription: "go-monthly" },
  });

  const selectSubscription = (subscription: string) => {
    setValue("subscription", subscription);
  };

  const selectedSubscription = watch("subscription");

  useEffect(() => {
    if (selectedSubscription === "") {
      setValue("subscription", selectedWorkspace.subscription);
    }
  }, [selectedWorkspace, selectedSubscription, setValue]);

  const handleTabSelection = (selection: string) => {
    setSelectedDuration(selection);
    if (selection === "monthly") {
      setValue("subscription", "go-monthly");
    } else {
      setValue("subscription", "go-annually");
    }
  };

  const clickUpdateQuantity = async (data: SubscriptionSchema) => {
    try {
      const subscribe = await upgradeSubscription({
        licenseAmount: data.licenseAmount,
        workspaceId: selectedWorkspace.id,
        subscription: selectedSubscription,
      });
      if (subscribe?.error === null) {
        window.location.href = subscribe?.url;
      } else {
        sendErrorNotification(
          t("dashboard.universal.notifications.workspace.resubscribe.failed")
        );
      }
    } catch (e) {
      return null;
    }
  };

  const renderOptions = () => {
    const filteredSubscriptions = SUBSCRIPTION_TYPES.filter(
      (sub) => sub.id !== "small"
    );
    if (selectedDuration === "monthly") {
      return filteredSubscriptions.filter(
        (x) => x.variant === "monthly" || x.variant === "small"
      );
    }
    return filteredSubscriptions.filter(
      (x) => x.variant === "annually" || x.variant === "small"
    );
  };

  return (
    <Form onSubmit={handleSubmit(clickUpdateQuantity)}>
      <ToggleWrapper>
        <ToggleButtons
          selected={selectedDuration}
          options={[
            {
              title: t(
                "register.views.select-subscription.payment-duration.monthly-selection.title"
              ),
              value: "monthly",
            },
            {
              title: t(
                "register.views.select-subscription.payment-duration.annually-selection.title"
              ),
              value: "annually",
            },
          ]}
          onSelect={handleTabSelection}
        />
      </ToggleWrapper>
      {renderOptions().map((types) => (
        <PriceDisplayContainer
          aria-label={t(types.ariaLabel)!}
          className={selectedSubscription === types.id ? "active" : ""}
          onClick={() => selectSubscription(types.id)}
        >
          <PriceDisplayRow>
            <PriceDetailsCol className="button-col my-auto">
              <SelectButton
                className={selectedSubscription === types.id ? "active" : ""}
              >
                {selectedSubscription === types.id
                  ? getIcon("selected-box")
                  : getIcon("unselected-box")}
              </SelectButton>
            </PriceDetailsCol>
            <PriceDetailsCol className="details-col">
              <PlanTitle>{t(types.title)}</PlanTitle>
              <PlanDescription>{t(types.description)}</PlanDescription>
            </PriceDetailsCol>
            <PriceDetailsCol className="price-col">
              <PriceTitle>{t(types.price)}</PriceTitle>
              <BillingTitle>{t(types.duration)}</BillingTitle>
            </PriceDetailsCol>
          </PriceDisplayRow>
        </PriceDisplayContainer>
      ))}
      <ModalContentContainer>
        <Field>
          <Label>
            {t(
              "dashboard.pages.workspace.change-subscription-modal.content.form-input.licenses-label"
            )}
          </Label>
          <Input
            register={register}
            error={errors["licenseAmount"]}
            variant="numeric"
            name="licenseAmount"
            ariaLabel={
              t(
                "dashboard.pages.workspace.change-subscription-modal.content.form-input.licenses-input.ariaLabel"
              )!
            }
            placeholder={
              t(
                "dashboard.pages.workspace.change-subscription-modal.content.form-input.licenses-input.placeholder"
              )!
            }
            min={1}
            max={50}
            disabled={selectedSubscription === "small"}
          />
        </Field>
        <DisclaimerText>
          Ved endring av abonnement eller justering av lisensantall vil du
          automatisk bli fakturert med justering for gjenværende
          faktureringsperiode. For mer informasjon om forskuddvisbetaling kan du
          lese mer på våre{" "}
          <Link target="_blank" to="/terms">
            vilkår
          </Link>
          .
        </DisclaimerText>
      </ModalContentContainer>
      <ModalButtonsContainer>
        <Button
          onClick={onClose}
          color="grey"
          spacing="right"
          variant="regular"
          type="button"
          ariaLabel={
            t(
              "dashboard.pages.workspace.change-subscription-modal.content.buttons.cancel.ariaLabel"
            )!
          }
        >
          {t(
            "dashboard.pages.workspace.change-subscription-modal.content.buttons.cancel.title"
          )}
        </Button>
        <Button
          color="blue"
          variant="regular"
          type="submit"
          ariaLabel={
            t(
              "dashboard.pages.workspace.change-subscription-modal.content.buttons.update.ariaLabel"
            )!
          }
        >
          {t(
            "dashboard.pages.workspace.change-subscription-modal.content.buttons.update.title"
          )}
        </Button>
      </ModalButtonsContainer>
    </Form>
  );
};

export default SubscriptionModal;
