import styled from "@emotion/styled";

export const StyledTitle = styled.h2`
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  display: block;
  color: #3b3b3b;

  &.size-xl {
    font-size: 2rem;
  }
  &.size-lg {
    font-size: 1.625rem;
  }
  &.size-md {
    font-size: 1.5rem;
  }
  &.size-sm {
    font-size: 1.25rem;
  }
  &.size-xs {
    font-size: 1.125rem;
  }

  @media (max-width: 768px) {
    &.size-xl {
      font-size: 1.875rem;
    }
    &.size-lg {
      font-size: 1.375rem;
    }
  }
`;
