import RCSlider from "rc-slider";
import "rc-slider/assets/index.css";

interface SliderProps {
  value: number;
  onChange: (value: number | number[]) => void;
  min: number;
  max: number;
  step?: number;
}

const Slider: React.FC<SliderProps> = ({ min, max, value, onChange }) => {
  return (
    <RCSlider min={min} max={max} value={value} onChange={onChange} dots />
  );
};

export default Slider;
