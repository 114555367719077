import styled from "@emotion/styled";
import { Accordion } from "react-bootstrap";

export const Section = styled.section`
  position: relative;
  background-color: #f6f9fb;
`;

export const Container = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 4.6875rem 1.25rem;
  padding-top: 200px;
  @media (max-width: 767px) {
    padding: 1rem 1.25rem;
  }
`;

export const ShowcaseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  max-width: 1300px;
  margin: 0 auto;
  padding: 40px 20px;

  @media (max-width: 900px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const IllustrationWrapper = styled.div`
  flex: 1;
`;

export const Illustration = styled.img`
  width: 100%;
  height: auto;
`;

export const DetailsWrapper = styled.div`
  flex: 1;
  button {
    display: block;
    margin-top: 1.5625rem;
  }
`;

export const TitleWrapper = styled.div``;

export const AccordionWrapper = styled(Accordion)`
  margin-top: 20px;
`;

export const AccordionItem = styled(Accordion.Item)`
  border: none;
  padding-left: 0rem !important;
  margin: 0rem !important;
  background: transparent;
  border-bottom: 0.0625rem solid #3b3b3b;
  &:last-of-type {
    border-bottom: none;
  }
  &:not(.collapsed) {
    border-color: #dee2e6 !important;
  }
`;

export const AccordionHeader = styled(Accordion.Header)`
  background: transparent;
  outline: none !important;
  button {
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 0rem;
    background: transparent !important;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    outline: none !important;
    color: grey;
    &:not(.collapsed) {
      color: #2f8de4 !important;
    }
  }
`;

export const AccordionBody = styled(Accordion.Body)`
  padding-left: 0rem !important;
`;
