import {
  HeaderWrapper,
  InnerContainer,
  Logo,
  TextWrapper,
  TitleWrapper,
} from "./styled";
import logo from "../../../assets/img/camtasia_studio_logo.svg";
import { Text } from "../../../library/text";
import { Title } from "../../../library/title";

const Header = () => {
  return (
    <HeaderWrapper>
      <InnerContainer>
        <Logo src={logo} />
        <TitleWrapper>
          <Title size="xl">
            Hvordan tekste video automatisk og importere undertekst til Camtasia
            Studio
          </Title>
        </TitleWrapper>
        <TextWrapper>
          <Text color="dark">
            Du kan enkelt bruke Randi til å tekste video automatisk og importere
            undertekst til Camtasia Studio. Følg stegene under for å komme i
            gang.
          </Text>
        </TextWrapper>
      </InnerContainer>
    </HeaderWrapper>
  );
};

export default Header;
