import { useCallback, useState } from "react";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "../firebase";

const useUpgradeSubscription = () => {
  const auth = getAuth(firebaseApp);
  const [loading, setLoading] = useState<boolean>(false);

  const upgradeSubscription = useCallback(
    async (details: any) => {
      try {
        setLoading(true);
        const token = await auth.currentUser?.getIdToken(true);
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/workspace/upgrade`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ ...details, token: token }),
          }
        );
        const response = await request.json();
        if (response.error === null) {
          setLoading(false);
          return { error: null, url: response.url };
        } else {
          throw response.error;
        }
      } catch (e) {
        setLoading(false);
        throw e;
      }
    },
    [auth?.currentUser]
  );

  return { upgradeSubscription, loading };
};

export default useUpgradeSubscription;
