import { Helmet } from "react-helmet";

const Meta = () => {
  return (
    <Helmet>
      <title>Randi - Hvordan importere undertekst til Filmora</title>
      <meta name="thumbnail" content="https://randi.ai/showcase.jpg" />
      <meta name="robots" content="index, follow" />
      <meta
        name="description"
        content="Du kan enkelt bruke Randi til å tekste video automatisk og importere undertekst til Filmora. Følg stegene under for å komme i gang."
      />
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:url"
        content="https://randi.ai/help-center/supported-software/filmora"
      />
      <meta
        name="twitter:title"
        content="Randi - Hvordan importere undertekst til Filmora"
      />
      <meta
        name="twitter:description"
        content="Du kan enkelt bruke Randi til å tekste video automatisk og importere undertekst til Filmora. Følg stegene under for å komme i gang."
      />
      <meta name="twitter:image" content="https://randi.ai/showcase.jpg" />

      <meta
        property="og:url"
        content="https://randi.ai/help-center/supported-software/filmora"
      />
      <meta property="og:type" content="article" />
      <meta
        property="og:title"
        content="Randi - Hvordan importere undertekst til Filmora"
      />
      <meta
        property="og:description"
        content="Du kan enkelt bruke Randi til å tekste video automatisk og importere undertekst til Filmora. Følg stegene under for å komme i gang."
      />
      <meta property="og:image" content="https://randi.ai/showcase.jpg" />
      <link
        rel="canonical"
        href="https://randi.ai/help-center/supported-software/filmora"
      />
    </Helmet>
  );
};

export default Meta;
