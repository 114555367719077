import styled from "@emotion/styled";

export const SelectionWrapper = styled.div``;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const Field = styled.div`
  margin-bottom: 15px;
  input {
    margin-top: 5px;
  }
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;

export const DetailCol = styled.div`
  &:first-of-type {
    width: 30%;
  }
  &:last-of-type {
    width: 70%;
  }
`;

export const Thumbnail = styled.img`
  width: 100%;
  max-width: 200px;
`;

export const SmallText = styled.small`
  font-family: Poppins, sans-serif;
  color: grey;
  font-size: 12px;
  padding-left: 20px;
  display: block;
  margin-top: 10px;
`;

export const NoResultTitle = styled.span`
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const SliderWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0px 1rem;
`;

export const FullRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DisplayValue = styled.span<{ active: boolean }>`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  margin-top: 10px;
  padding-right: 1rem;
  font-weight: 500;
  color: ${({ active }) => (active ? "#2f8de4" : "#ff4d6d")};
`;
