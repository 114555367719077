import { useTranslation } from "react-i18next";
import Button from "../../../library/button";
import { Text } from "../../../library/text";
import { Title } from "../../../library/title";
import {
  Container,
  Section,
  ButtonsContainer,
  DescriptionWrapper,
  LeftArrows,
  RightArrows,
} from "./styled";
import leftArrows from "../../../assets/img/left_arrows.svg";
import rightArrows from "../../../assets/img/right_arrows.svg";
import { Link } from "react-router-dom";

const BottomView = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Container>
        <Title>{t("frontpage.sections.bottom-view.title")}</Title>
        <DescriptionWrapper>
          <Text color="dark">{t("frontpage.sections.bottom-view.text")}</Text>
        </DescriptionWrapper>
        <ButtonsContainer>
          <LeftArrows src={leftArrows} />
          <Link
            aria-label={
              t("frontpage.sections.bottom-view.link.ariaLabel") as string
            }
            to="/register"
          >
            <Button
              color="blue"
              variant="regular"
              ariaLabel="Registrer ny bruker"
            >
              {t("frontpage.sections.bottom-view.button")}
            </Button>
          </Link>
          <RightArrows src={rightArrows} />
        </ButtonsContainer>
      </Container>
    </Section>
  );
};

export default BottomView;
