export const checkVideoDuration = (file: File): Promise<number | null> => {
  return new Promise((resolve) => {
    const videoElement = document.createElement("video");
    videoElement.preload = "metadata";

    const videoUrl = URL.createObjectURL(file);
    videoElement.src = videoUrl;

    videoElement.onloadedmetadata = () => {
      URL.revokeObjectURL(videoUrl);
      resolve(videoElement.duration);
    };

    videoElement.onerror = () => {
      URL.revokeObjectURL(videoUrl);
      resolve(null);
    };
  });
};
