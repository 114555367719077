import styled from "@emotion/styled";

export const BoxWrapper = styled.div`
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0px 0px 15px rgba(223, 223, 223, 0.25);
  margin: 0.625rem;
  a {
    text-decoration: none;
  }
`;

export const BoxContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  padding: 0.75rem 1.25rem;
`;

export const Thumbnail = styled.img`
  width: 100%;
  object-fit: contain;
  width: 32.5px;
  border-radius: 10px;
`;

export const BoxName = styled.span`
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  color: #3b3b3b;
`;
