import Footer from "../../components/PublicFooter";
import Navigation from "../../components/PublicNavigation";
import LogosFeaturesView from "./logos-features-view";
import BottomView from "./bottom-view";
import Header from "./header";
import LatestNews from "./latest-news";
import UsecasesView from "./usecases-view";
import SupportedPrograms from "./supported-programs";
import SelectionView from "./selection-view";
import Meta from "./meta";
import ShowcaseView from "./showcase-view";

const Index = () => {
  return (
    <>
      <Meta />
      <Navigation />
      <Header />
      <LogosFeaturesView />
      <ShowcaseView />
      <SupportedPrograms />
      <UsecasesView />
      {false && <SelectionView />}
      <LatestNews />
      <BottomView />
      <Footer />
    </>
  );
};

export default Index;
