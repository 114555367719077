import styled from "@emotion/styled";

export const Container = styled.div`
  background-color: #f7fafc;
`;

export const InnerWrapper = styled.div`
  max-width: 78.125rem;
  margin: 0 auto;
  padding: 4.6875rem 1.25rem;
`;

export const DetailTitle = styled.span`
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #2f8de4;
`;

export const ListWrapper = styled.div`
  flex: 1;
`;

export const SelectionsWrapper = styled.section`
  display: flex;
  text-align: left;
  justify-content: left;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 30px;
  padding: 20px 20px;
  margin: 0 auto;

  @media (max-width: 767px) {
    padding: 0 0px;
  }
`;

export const ProgramCard = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0px 0px 15px rgba(223, 223, 223, 0.25);
  flex: 1 1 calc(25% - 20px); /* Allows it to shrink and respect 3-column layout */
  min-width: 280px;
  max-width: calc(25% + 20 * 4px);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
  a {
    text-decoration: none;
  }

  &:hover {
    transform: translateY(-5px);
    cursor: pointer;
  }

  @media (max-width: 980px) {
    flex: 1 1 calc(100% - 20px);
    max-width: 100%;
  }
`;

export const DescriptionWrapper = styled.div`
  display: block;
  max-width: 48.75rem;
  margin: 0 auto;
  margin-top: 0.625rem;
`;

export const InnerProgram = styled.div`
  padding: 0.75rem 1rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

export const Thumbnail = styled.img`
  width: 100%;
  object-fit: contain;
  width: 35px;
`;

export const BoxName = styled.span`
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #3b3b3b;
`;

export const ProgramContent = styled.div`
  gap: 12.5px;
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  i {
    color: grey;
  }
`;
