import { Caption as CaptionType } from "../../../../../../../types";
import { getIcon } from "../../../../../../../utils/get-icon";
import Dropdown from "../../../../../../../library/dropdown-menu";
import {
  AddButton,
  BindingIcon,
  CaptionWrapper,
  InnerCaptionWrapper,
  MenuButton,
  TimestampInput,
  UpperCol,
  UpperRow,
} from "./styled";
import ContentEditable from "react-contenteditable";
import {
  ForwardRefRenderFunction,
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { formatTime } from "../../../helpers";
import {
  clickNewCaption,
  findActiveCaptionKey,
  handleTimestampBlur,
  handleTimestampChange,
  INPUT_TIME_PATTERN,
  INPUT_TIME_PLACEHOLDER,
  onContentBlur,
  selectCaption,
} from "../utils";
import { useVideoEditor } from "../../../context";

type DesktopCaptionProps = {
  caption: CaptionType;
  captionRef: RefObject<HTMLDivElement>;
  selections: any[];
  handleAdjust: (key: string, method: "delete" | "change") => void;
  clickSeek: (time: number) => void;
};

const DesktopCaption: ForwardRefRenderFunction<
  HTMLDivElement,
  DesktopCaptionProps
> = ({ caption, captionRef, selections, handleAdjust, clickSeek }) => {
  const { currentPlayerTime, setValue, captionsRef, captions } =
    useVideoEditor();
  const [currentText, setCurrentText] = useState(caption?.text || "");
  const startInputRef = useRef<HTMLInputElement | null>(null);
  const endInputRef = useRef<HTMLInputElement | null>(null);

  const start = formatTime(caption?.start);
  const end = formatTime(caption?.end);

  const [duration, setDuration] = useState<{ start: string; end: string }>({
    start: start,
    end: end,
  });

  useEffect(() => {
    if (currentText) return;
    setCurrentText(caption?.text || "");
  }, [caption?.text, currentText]);

  const blurInput = (type: "start" | "end") => {
    if (type === "start") {
      startInputRef.current?.blur();
    } else {
      endInputRef.current?.blur();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    key: string,
    type: "start" | "end"
  ) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      ":",
      ".",
    ];

    if (e.key >= "0" && e.key <= "9") {
      return;
    }

    if (allowedKeys.includes(e.key)) {
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      blurInput(type);
      handleTimestampBlur(
        key,
        type,
        duration,
        captionsRef.current,
        handleAdjust,
        setValue,
        setDuration
      );
      return;
    }

    e.preventDefault();
  };

  const onContentChange = useCallback((evt: any) => {
    setCurrentText(evt.target.value);
  }, []);

  const onContentKeyDown = useCallback(
    (evt: React.KeyboardEvent<HTMLDivElement>) => {
      if (evt.key === "Enter") {
        evt.preventDefault();
        (evt.target as HTMLElement).blur();
      }
    },
    []
  );

  const handleContentBlur = (key: any, content: any) => {
    onContentBlur(key, content, captionsRef.current, setValue);
  };

  const safeValue = typeof currentText === "string" ? currentText : "";

  const activeCaptionKey = findActiveCaptionKey(currentPlayerTime, captions);
  const isActive = caption.key === activeCaptionKey;

  return (
    <CaptionWrapper
      ref={isActive ? captionRef : null}
      onClick={() => selectCaption(currentPlayerTime, start, end, clickSeek)}
      /* id={caption.key} */
    >
      <InnerCaptionWrapper className={isActive ? "active" : ""}>
        <UpperRow>
          <UpperCol className="left-aligned my-auto">
            <TimestampInput
              type="text"
              placeholder={INPUT_TIME_PLACEHOLDER}
              id="captionStart"
              maxLength={12}
              value={duration.start}
              onChange={(e) =>
                handleTimestampChange(e, "start", duration, setDuration)
              }
              onKeyDown={(e) => handleKeyDown(e, caption.key, "start")}
              onBlur={() =>
                handleTimestampBlur(
                  caption.key,
                  "start",
                  duration,
                  captionsRef.current,
                  handleAdjust,
                  setValue,
                  setDuration
                )
              }
              pattern={INPUT_TIME_PATTERN}
              ref={startInputRef}
            />

            <BindingIcon className="bind-icon">
              {getIcon("arrow-right")}
            </BindingIcon>
            <TimestampInput
              type="text"
              placeholder={INPUT_TIME_PLACEHOLDER}
              id="captionEnd"
              value={duration.end}
              maxLength={12}
              onChange={(e) =>
                handleTimestampChange(e, "end", duration, setDuration)
              }
              onKeyDown={(e) => handleKeyDown(e, caption.key, "end")}
              onBlur={() =>
                handleTimestampBlur(
                  caption.key,
                  "end",
                  duration,
                  captionsRef.current,
                  handleAdjust,
                  setValue,
                  setDuration
                )
              }
              pattern={INPUT_TIME_PATTERN}
              ref={endInputRef}
            />
          </UpperCol>
          <UpperCol className="delete right-aligned">
            <Dropdown
              selections={selections}
              title={"Velg handling"}
              toggle={<MenuButton>{getIcon("caption-menu")}</MenuButton>}
              ariaLabel="Velg handling"
              variant="button"
            />
          </UpperCol>
        </UpperRow>
        <ContentEditable
          onChange={onContentChange}
          contentEditable
          html={safeValue}
          onBlur={(evt) => handleContentBlur(caption.key, evt.target.innerHTML)}
          onKeyDown={onContentKeyDown}
          className={`caption-text ${safeValue ? "" : "placeholder"}`}
        />
        <AddButton
          className="add-button"
          onClick={() =>
            clickNewCaption(captionsRef.current, caption, setValue)
          }
        >
          {getIcon("plus")}
        </AddButton>
      </InnerCaptionWrapper>
    </CaptionWrapper>
  );
};

export default forwardRef(DesktopCaption);
