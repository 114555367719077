import { FileDrop } from "react-file-drop";
import { InteractionContainer } from "./styled";
import { useTranslation } from "react-i18next";

import DroppedView from "./dropped";
import NotDroppedView from "./drop-view";
import { Text } from "../../../../../../library/text";
import { sendErrorNotification } from "../../../../../../library/notification";
import { UseFormSetValue } from "react-hook-form";
import { UploadTranscriptSchema } from "../../schema";
import { checkVideoDuration } from "../utils";
import { ErrorWrapper } from "../styled";
import { isFileTypeAllowed } from "../../../utils";

interface UploadViewProps {
  setValue: UseFormSetValue<UploadTranscriptSchema>;
  selectedFile: File;
  errors: any;
}

const UploadView: React.FC<UploadViewProps> = ({
  setValue,
  selectedFile,
  errors,
}) => {
  const { t } = useTranslation();

  const handleFileDrop = async (e: any, type: string) => {
    try {
      const file = type === "click" ? e.target.files[0] : e[0];

      if (!isFileTypeAllowed(file)) {
        throw Error("Filtype støttes ikke");
      }

      const duration = await checkVideoDuration(file);

      setValue("file", file);
      setValue("duration", duration);
    } catch (error) {
      sendErrorNotification("Filtypen du prøvde å laste opp støttes ikke.");
    }
  };

  const renderError = (type: "text" | "visual") => {
    if (!selectedFile && errors["file"]?.message) {
      return type === "visual" ? "error" : errors["file"]?.message;
    }
    return "";
  };

  return (
    <div>
      <FileDrop onDrop={(f) => handleFileDrop(f, "dragdrop")}>
        <InteractionContainer
          aria-label={
            t(
              "dashboard.pages.transcripts.add-modal.content.select-source-step.upload-container.ariaLabel"
            ) as string
          }
          className={`${renderError("visual")}`}
        >
          {selectedFile?.name ? (
            <DroppedView selectedFile={selectedFile} setValue={setValue} />
          ) : (
            <NotDroppedView handleFileDrop={handleFileDrop} />
          )}

          {renderError("text") && (
            <ErrorWrapper>
              <Text color="fail" size="small">
                {t(
                  "dashboard.pages.transcripts.add-modal.content.select-source-step.upload-container.error"
                )}
              </Text>
            </ErrorWrapper>
          )}
        </InteractionContainer>
      </FileDrop>
    </div>
  );
};

export default UploadView;
