import { useCallback } from "react";
import { useSelector } from "react-redux";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";
import { IState } from "../types";

const useDownloadTranscript = () => {
  const auth = getAuth(firebaseApp);
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );

  const downloadTranscript = useCallback(
    async (
      transcriptId: string,
      type:
        | "video-subtitles"
        | "text"
        | "pdf"
        | "csv"
        | "html"
        | "docx"
        | "srt"
        | "vtt",
      fileName: string
    ) => {
      try {
        const token = await auth.currentUser?.getIdToken(true);
        let endpointUrl = "";
        switch (type) {
          case "text":
            endpointUrl += `${process.env.REACT_APP_BACKEND_ENDPOINT}/utils/download/generate-text?workspaceId=${selectedWorkspace.id}&transcriptId=${transcriptId}&token=${token}`;
            break;
          case "vtt":
            endpointUrl += `${process.env.REACT_APP_BACKEND_ENDPOINT}/utils/download/generate-vtt?workspaceId=${selectedWorkspace.id}&transcriptId=${transcriptId}&token=${token}`;
            break;
          case "srt":
            endpointUrl += `${process.env.REACT_APP_BACKEND_ENDPOINT}/utils/download/generate-srt?workspaceId=${selectedWorkspace.id}&transcriptId=${transcriptId}&token=${token}`;
            break;
          case "html":
            endpointUrl += `${process.env.REACT_APP_BACKEND_ENDPOINT}/utils/download/generate-html?workspaceId=${selectedWorkspace.id}&transcriptId=${transcriptId}&token=${token}`;
            break;
          case "pdf":
            endpointUrl += `${process.env.REACT_APP_BACKEND_ENDPOINT}/utils/download/generate-pdf?workspaceId=${selectedWorkspace.id}&transcriptId=${transcriptId}&token=${token}`;
            break;
          case "docx":
            endpointUrl += `${process.env.REACT_APP_BACKEND_ENDPOINT}/utils/download/generate-docx?workspaceId=${selectedWorkspace.id}&transcriptId=${transcriptId}&token=${token}`;
            break;
          case "csv":
            endpointUrl += `${process.env.REACT_APP_BACKEND_ENDPOINT}/utils/download/generate-csv?workspaceId=${selectedWorkspace.id}&transcriptId=${transcriptId}&token=${token}`;
            break;
          default:
            break;
        }

        const response = await fetch(endpointUrl, {
          method: "GET",
        });

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || "Error downloading file");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download =
          type === "text" ? `${fileName}.txt` : `${fileName}.${type}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);

        return { error: null };
      } catch (e) {
        throw e;
      }
    },
    [auth.currentUser, selectedWorkspace.id]
  );

  return { downloadTranscript };
};

export default useDownloadTranscript;
