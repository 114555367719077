import {
  HeaderWrapper,
  InnerContainer,
  TextWrapper,
  TitleWrapper,
} from "./styled";
import { Text } from "../../../library/text";
import { Title } from "../../../library/title";

const Header = () => {
  return (
    <HeaderWrapper>
      <InnerContainer>
        <TitleWrapper>
          <Title size="xl">Hjelpesenter</Title>
        </TitleWrapper>
        <TextWrapper>
          <Text color="dark">
            Velkommen til hjelpesenteret vårt. Her finner du en oversikt over
            hvordan du tar i bruk Randi og hvordan du kan bruke det med andre
            redigeringsverktøy.
          </Text>
        </TextWrapper>
      </InnerContainer>
    </HeaderWrapper>
  );
};

export default Header;
