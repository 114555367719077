import styled from "@emotion/styled";

export const RecordWrapper = styled.div`
  border: 0.125rem dashed #e1e1e1;
  padding: 25px 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  margin-top: 25px;

  label {
    padding-left: 0rem;
  }
  .replay {
    display: block;
    margin: 0 auto;
    margin-top: 10px;
  }

  &.error {
    border-color: #ed0c60;
  }
`;

export const TopWrapper = styled.div``;

export const ActionButton = styled.button`
  height: 2.6563rem;
  width: 2.6563rem;
  border-radius: 100%;
  display: block;
  background-color: #2f8de4;
  margin: 0 auto;
  display: block;
  color: #fff;
  border: none;
  margin-bottom: 0.625rem;
  &.recording {
    animation: pulse-animation 2s infinite;
  }
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(47, 141, 228, 0.4);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(47, 141, 228, 0);
    }
  }
`;

export const InputWrapper = styled.div`
  margin-top: 25px;
`;

export const Field = styled.div`
  margin-bottom: 15px;
  input {
    width: 100%;
    margin-top: 5px;
  }
  label {
    padding-left: 20px;
  }
`;
