import { UploadTranscriptSchema } from "../schema";
import { UseFormSetValue } from "react-hook-form";
import { Dispatch, SetStateAction } from "react";
import Selections from "../../../../../library/selections";
import { ButtonsWrapper, SelectionsWrapper, SelectionWrapper } from "./styled";
import Button from "../../../../../library/button";
import { useTranslation } from "react-i18next";
import { OPTIONS } from "./options";
import { useAppSelector } from "../../../../../redux/hooks";
import { IState } from "../../../../../types";
import UploadingView from "./uploading-view";

interface FormatViewProps {
  setValue: UseFormSetValue<UploadTranscriptSchema>;
  selectedFormat: string;
  setSelectionType: (type: "extra-short" | "short" | "long") => void;
  setStep: Dispatch<SetStateAction<1 | 2 | 3 | 4>>;
  progress: number;
}

const FormatView = ({
  selectedFormat,
  setStep,
  setSelectionType,
  progress,
}: FormatViewProps) => {
  const uploading = useAppSelector(
    (state: IState) => state.transcripts.loadingUpload
  );
  const { t } = useTranslation();

  const handleSelection = (type: any) => {
    setSelectionType(type?.value);
  };

  const clickBack = () => {
    setStep(2);
  };

  return (
    <SelectionWrapper>
      {uploading ? (
        <UploadingView progress={progress} />
      ) : (
        <>
          <SelectionsWrapper>
            <Selections
              defaultSelected={selectedFormat}
              options={OPTIONS}
              onSelect={handleSelection}
              type="default"
            />
          </SelectionsWrapper>
          <ButtonsWrapper>
            <Button
              ariaLabel={
                t(
                  "dashboard.pages.transcripts.add-modal.content.format-step.buttons.back.ariaLabel"
                ) as string
              }
              onClick={clickBack}
              variant="regular"
              color="grey"
            >
              {t(
                "dashboard.pages.transcripts.add-modal.content.format-step.buttons.back.title"
              )}
            </Button>
            <Button
              type="submit"
              variant="regular"
              color="blue"
              ariaLabel={
                t(
                  "dashboard.pages.transcripts.add-modal.content.format-step.buttons.start.ariaLabel"
                ) as string
              }
            >
              {t(
                "dashboard.pages.transcripts.add-modal.content.format-step.buttons.start.title"
              )}
            </Button>
          </ButtonsWrapper>
        </>
      )}
    </SelectionWrapper>
  );
};

export default FormatView;
