import { useCallback, useState } from "react";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";
import { setTranscriptUploading } from "../redux/slices/transcripts";
import {
  getStorage,
  uploadBytesResumable,
  ref,
  UploadMetadata,
} from "firebase/storage";
import { IState } from "../types";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

const useCreateTranscript = (afterSave: any) => {
  const dispatch = useAppDispatch();
  const [progress, setProgress] = useState(0);
  const selectedWorkspace = useAppSelector(
    (state: IState) => state.user.selectedWorkspace
  );
  const auth = getAuth(firebaseApp);
  const storage = getStorage(firebaseApp);

  const uploadTranscript = useCallback(
    async (details: any) => {
      try {
        dispatch(setTranscriptUploading(true));
        const token = await auth.currentUser?.getIdToken(true);
        if (token) {
          const request = await fetch(
            `${process.env.REACT_APP_BACKEND_ENDPOINT}/transcription/start`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                token: token,
                workspaceId: selectedWorkspace.id,
                language: details.language,
                format: details.format,
                filename: details.file.name,
                public: details.public,
                videoDuration: details.duration,
                temperature: details.settings.temperature,
              }),
            }
          );
          const response = await request.json();
          if (response?.error) {
            throw response.error;
          }

          const storageRef = ref(
            storage,
            `workspaces/${selectedWorkspace.id}/transcripts/${response.result}/${details.file.name}`
          );
          const metadata: UploadMetadata = {
            customMetadata: {
              pathId: response.result,
              inProgress: "true",
              workspaceId: selectedWorkspace.id,
              language: details.language,
              format: details.format,
              temperature: details.settings.temperature,
              token: token,
            },
          };
          const uploadFile = uploadBytesResumable(
            storageRef,
            details.file,
            metadata
          );

          uploadFile.on(
            "state_changed",
            (snapshot) => {
              const progressCount =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgress(progressCount);
            },
            (error) => {
              throw error;
            },
            () => {
              setProgress(0);
              dispatch(setTranscriptUploading(false));
              afterSave();
              return "success";
            }
          );
        }
      } catch (e) {
        throw e;
      }
    },
    [afterSave, auth.currentUser, dispatch, storage, selectedWorkspace.id]
  );

  return { uploadTranscript, progress };
};

export default useCreateTranscript;
