import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const Section = styled.section`
  background-color: #f6f9fb;
`;

export const Container = styled.div`
  max-width: 78.125rem;
  margin: 0 auto;
  padding: 4.6875rem 1.25rem;
`;

export const Wrapper = styled.div`
  @media (max-width: 767px) {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
`;

export const NewsRow = styled(Row)`
  margin-top: 3.125rem;
`;

export const ArticleCol = styled(Col)`
  a {
    text-decoration: none;
  }
`;

export const ArticleWrapper = styled.div``;

export const Thumbnail = styled.img`
  width: 100%;
  border-radius: 1.75rem;
`;

export const Published = styled.span`
  color: #2f8de4;
  font-family: "Poppins", sans-serif;
  display: block;
  margin-top: 0.9375rem;
  font-size: 0.875rem;
  font-weight: 500;
`;

export const Title = styled.h2`
  font-family: "Poppins", sans-serif;
  color: black;
  font-size: 1.75rem;
  margin-top: 0.1563rem;
`;

export const Excerpt = styled.p`
  font-family: "Poppins", sans-serif;
  color: grey;
  font-size: 1rem;
  line-height: 1.5625rem;
`;
