import { Text } from "../../../library/text";
import { Title } from "../../../library/title";
import {
  Container,
  DetailsWrapper,
  Illustration,
  IllustrationWrapper,
  Section,
  Separator,
  ShowcaseWrapper,
  TextWrapper,
  TitleWrapper,
  Wrapper,
} from "./styled";
import middleSeparator from "../../../assets/img/middle_separator.svg";
import middleIllustration from "../../../assets/img/showcase_illustration.svg";
import { useTranslation } from "react-i18next";
import Button from "../../../library/button";
import { Link } from "react-router-dom";

const SelectionView = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Separator
        src={middleSeparator}
        alt={t("frontpage.sections.header.bottom-separator.alt")!}
      />
      <Container>
        <Wrapper>
          <ShowcaseWrapper>
            <IllustrationWrapper>
              <Illustration src={middleIllustration} />
            </IllustrationWrapper>
            <DetailsWrapper>
              <TitleWrapper>
                <Title>{t("frontpage.sections.highlight-view.title")}</Title>
              </TitleWrapper>
              <TextWrapper>
                <Text color="light">
                  {t("frontpage.sections.highlight-view.text")}
                </Text>
              </TextWrapper>
              <Link to="/register">
                <Button variant="regular" color="white">
                  {t("frontpage.sections.highlight-view.button")}
                </Button>
              </Link>
            </DetailsWrapper>
          </ShowcaseWrapper>
        </Wrapper>
      </Container>
    </Section>
  );
};
export default SelectionView;
