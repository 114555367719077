import {
  Container,
  FooterWrapper,
  FooterCol,
  FooterLink,
  FooterLinkWrapper,
  FooterLogo,
  FooterRow,
  FooterTitle,
} from "./styled";
import logo from "../../assets/img/logo-2.svg";
import { Text } from "../../library/text";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NewsletterForm from "./newsletter-form";

const Footer = () => {
  const { t } = useTranslation();

  const clickCookies = () => {
    document.getElementById("cookiescript_badge")?.click();
  };

  return (
    <FooterWrapper>
      <Container>
        <FooterRow>
          <FooterCol md={3} className="my-auto">
            <Link to="/">
              <FooterLogo alt="Randi logo" src={logo} />
            </Link>
          </FooterCol>
          <FooterCol md={3}>
            <FooterTitle>
              {t("components.footer.useful-links-block.title")}
            </FooterTitle>
            <FooterLinkWrapper>
              <FooterLink
                aria-label={
                  t(
                    "components.footer.useful-links-block.links.privacy-policy.ariaLabel"
                  ) as string
                }
                to="/privacy-policy"
              >
                {t(
                  "components.footer.useful-links-block.links.privacy-policy.title"
                )}
              </FooterLink>
              <FooterLink
                aria-label={
                  t(
                    "components.footer.useful-links-block.links.terms.ariaLabel"
                  ) as string
                }
                to="/terms"
              >
                {t("components.footer.useful-links-block.links.terms.title")}
              </FooterLink>
              <FooterLink
                aria-label={
                  t(
                    "components.footer.useful-links-block.links.cookies.ariaLabel"
                  ) as string
                }
                to="/cookies"
              >
                {t("components.footer.useful-links-block.links.cookies.title")}
              </FooterLink>
              <FooterLink
                aria-label={
                  t(
                    "components.footer.useful-links-block.links.contact-us.ariaLabel"
                  ) as string
                }
                to="/contact"
              >
                {t(
                  "components.footer.useful-links-block.links.contact-us.title"
                )}
              </FooterLink>
            </FooterLinkWrapper>
          </FooterCol>
          <FooterCol md={3}>
            <FooterTitle>
              {t("components.footer.information-block.title")}
            </FooterTitle>
            <FooterLinkWrapper>
              <FooterLink
                aria-label={
                  t(
                    "components.footer.information-block.links.blog.ariaLabel"
                  ) as string
                }
                to="/blog"
              >
                {t("components.footer.information-block.links.blog.title")}
              </FooterLink>
              <FooterLink
                aria-label={
                  t(
                    "components.footer.information-block.links.functionality.ariaLabel"
                  ) as string
                }
                to="/#"
              >
                {t(
                  "components.footer.information-block.links.functionality.title"
                )}
              </FooterLink>
              <FooterLink
                aria-label={
                  t(
                    "components.footer.information-block.links.help-center.ariaLabel"
                  ) as string
                }
                to="/help-center"
              >
                {t(
                  "components.footer.information-block.links.help-center.title"
                )}
              </FooterLink>
              <FooterLink onClick={clickCookies} to="#">
                Sideinnstillinger
              </FooterLink>
            </FooterLinkWrapper>
          </FooterCol>
          <FooterCol md={3}>
            <FooterTitle>
              {t("components.footer.subscribe-newsletter-block.title")}
            </FooterTitle>
            <Text color="dark">
              {t("components.footer.subscribe-newsletter-block.text")}
            </Text>
            <NewsletterForm />
          </FooterCol>
        </FooterRow>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
