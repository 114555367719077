import {
  Container,
  InnerWrapper,
  Section,
  TextWrapper,
  IllustrationWrapper,
  OrderedList,
  ListItem,
  Bold,
  Illustration,
  IllustrationArrow,
  BottomTextWrapper,
} from "./styled";
import { Title } from "../../../library/title";
import { Text } from "../../../library/text";
import Button from "../../../library/button";
import illustrationArrow from "../../../assets/img/illustration_arrow.svg";
import uploadThumbnail from "../../../assets/img/upload-thumbnail.svg";
import importThumbnail from "../../../assets/img/import-thumbnail.svg";
import { Link } from "react-router-dom";

const Content = () => {
  return (
    <Container>
      <InnerWrapper>
        <Section bottom={false}>
          <TextWrapper>
            <Title>Steg 1 - transkriber med Randi</Title>
            <Text color="dark">
              Logg inn på Randi-brukeren din og trykk på "Transkripter" øverst i
              menyen og gjør deretter følgende:
            </Text>
            <OrderedList>
              <ListItem>
                Trykk på <Bold>Nytt transkript</Bold>-knappen øverst til høyre.
              </ListItem>
              <ListItem>
                Fra modalen som dukker opp, trykk på <Bold>Velg fil</Bold> eller
                dra og slipp ønsket fil inn i filvalg-boksen.
              </ListItem>
              <ListItem>
                Velg om filen skal være kun tilgjengelig for deg selv eller
                andre i arbeidsmiljøet ditt og trykk på <Bold>Gå videre</Bold>.
              </ListItem>
              <ListItem>
                Velg deretter språket du ønsker at underteksten din skal tekstes
                til og juster <Bold>Språklig frihet</Bold> etter behov. Trykk så{" "}
                <Bold>Gå videre</Bold>.
              </ListItem>
              <ListItem>
                Til slutt velger du hva slags format du ønsker at underteksten
                din skal ha (tekstlengde) og trykk til slutt på{" "}
                <Bold>Start transkribering</Bold>.
              </ListItem>
            </OrderedList>
            <Text color="dark">
              Trykk til slutt på <Bold>Last ned</Bold> og velg enten SRT-format
              eller VTT format - DaVinci Resolve støtter begge.
            </Text>
          </TextWrapper>
          <IllustrationWrapper>
            <Illustration src={uploadThumbnail} />
          </IllustrationWrapper>
          <IllustrationArrow src={illustrationArrow} />
        </Section>
        <Section bottom={false}>
          <IllustrationWrapper>
            <Illustration src={importThumbnail} />
          </IllustrationWrapper>
          <TextWrapper>
            <Title>Steg 2 - importer til DaVinci Resolve</Title>
            <Text color="dark">
              Etter at du har lastet ned undertekstfilen din fra Randi, kan du
              legge den til i DaVinci Resolve ved å følge disse stegene:
            </Text>
            <OrderedList>
              <ListItem>
                Åpne <Bold>DaVinci Resolve</Bold> og last inn videoprosjektet
                ditt.
              </ListItem>
              <ListItem>
                Gå til <Bold>Edit</Bold>-fanen for å arbeide med tidslinjen.
              </ListItem>
              <ListItem>
                Importer underteksten ved å høyreklikke i{" "}
                <Bold>Media Pool</Bold>, velge <Bold>Import Subtitle</Bold> og
                deretter velge undertekstfilen din.
              </ListItem>
              <ListItem>
                Dra undertekstfilen til tidslinjen for å plassere den riktig i
                videoen.
              </ListItem>
              <ListItem>
                Tilpass utseende ved å dobbeltklikke på undertekstsporet og
                bruke <Bold>Inspector</Bold>-panelet til å gjøre visuelle
                endringer.
              </ListItem>
              <ListItem>
                Eksporter videoen ved å gå til <Bold>Deliver</Bold>-fanen, velge
                ønsket format og sørge for at underteksten er inkludert som
                burned-in eller som en separat tekstfil.
              </ListItem>
            </OrderedList>
          </TextWrapper>
        </Section>
        <Section bottom={true}>
          <Title>Tekst din video gratis med Randi</Title>
          <BottomTextWrapper>
            <Text color="dark">
              Kom i gang med automatisk teksting av video helt gratis og få opp
              til 2 opplastinger hver måned på videoer opp til 20 minutter.
            </Text>
          </BottomTextWrapper>
          <Link to="/register">
            <Button color="blue" variant="regular">
              Registrer gratis bruker
            </Button>
          </Link>
        </Section>
      </InnerWrapper>
    </Container>
  );
};

export default Content;
