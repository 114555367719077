import styled from "@emotion/styled";

export const StyledButton = styled.button`
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  transition: 0.38s;
  &.color-blue {
    background-color: #2f8de4 !important;
    color: #fff;
    &:hover {
      background-color: #2b84d7 !important;
    }
    &:disabled {
      background-color: #5aa8e5 !important;
      color: #fff;
    }
  }
  &.color-grey {
    color: grey;
    background-color: #f4f4f4;
    &:hover {
      background-color: #ececec;
    }
    &:disabled {
      cursor: not-allowed;
      &:hover {
        background-color: #f4f4f4;
        color: grey !important;
      }
    }
  }

  &.color-outline {
    color: #fff;
    border: 2px solid #fff;
    background-color: transparent;
    &:hover {
      background-color: #fff;
      color: #2b84d7;
    }
  }
  &.color-white {
    color: #2b84d7;
    background-color: #fff;
  }
  &.size-sm {
    height: 2.1875rem;
    font-size: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-radius: 0.75rem;
  }
  &.color-red {
    color: #fff;
    background-color: #ff4d6d;
  }
  &.size-md {
    height: 2.8rem;
    border-radius: 0.875rem;
    font-size: 0.875rem;
    padding-left: 1.5188rem;
    padding-right: 1.5188rem;
  }
  &.size-lg {
    height: 3.125rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }
  &.spacing-right {
    margin-right: 0.9375rem;
  }
  &.spacing-left {
    margin-left: 0.9375rem;
  }
  i {
    margin-right: 0.3125rem;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const StyledIconButton = styled.button`
  border: none;
  border-radius: 0.625rem;
  &.size-md {
    font-size: 0.8125rem;
    height: 2.5rem;
    min-width: 2.5rem;
  }
  &.size-lg {
    height: 2.9688rem;
    width: 2.9688rem;
  }
  &.color-grey {
    color: grey;
    background-color: #f4f4f4;
    &:disabled {
      cursor: not-allowed;
      &:hover {
        background-color: #f4f4f4;
        color: grey !important;
      }
    }
  }

  &.color-blue {
    background-color: #2f8de4;
    color: #fff;
    &:hover {
      background-color: #2f8de4;
      color: #fff;
    }
  }
  &.color-transparent {
    color: grey;
    background-color: transparent;
  }
  &.spacing-right {
    margin-right: 0.9375rem;
  }
  &.spacing-left {
    margin-left: 0.9375rem;
  }
`;
