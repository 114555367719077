export const OPTIONS = [
  {
    id: "long",
    title:
      "dashboard.pages.transcripts.add-modal.content.format-step.fourth-selection.title",
    description:
      "dashboard.pages.transcripts.add-modal.content.format-step.fourth-selection.description",
    value: "long",
    icon: "tv",
  },
  {
    id: "short",
    title:
      "dashboard.pages.transcripts.add-modal.content.format-step.third-selection.title",
    description:
      "dashboard.pages.transcripts.add-modal.content.format-step.third-selection.description",
    value: "short",
    icon: "tablet",
  },
  {
    id: "extra-short",
    title:
      "dashboard.pages.transcripts.add-modal.content.format-step.second-selection.title",
    description:
      "dashboard.pages.transcripts.add-modal.content.format-step.second-selection.description",
    value: "extra-short",
    icon: "mobile",
  },
  {
    id: "word",
    title:
      "dashboard.pages.transcripts.add-modal.content.format-step.first-selection.title",
    description:
      "dashboard.pages.transcripts.add-modal.content.format-step.first-selection.description",
    value: "word",
    icon: "word",
  },
];
