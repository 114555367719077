import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const FooterWrapper = styled.footer`
  background-color: #f6f9fb;
`;

export const Container = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 4.6875rem 1.25rem 1.5625rem 1.25rem;
`;

export const FooterRow = styled(Row)``;

export const FooterCol = styled(Col)`
  @media (max-width: 767px) {
    padding-bottom: 1.5625rem;
    &:last-of-type {
      padding-bottom: 0rem;
    }
  }
`;

export const FooterTitle = styled.h5`
  font-family: "Poppins", sans-serif;
  color: #3b3b3b;
  font-weight: 600;
  @media (max-width: 767px) {
    text-align: center;
  }
`;

export const FooterLinkWrapper = styled.div``;

export const FooterLink = styled(Link)`
  display: block;
  color: grey;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.8rem;
  text-decoration: none;
  &:hover {
    color: #3b3b3b;
  }
  @media (max-width: 767px) {
    text-align: center;
  }
`;

export const FooterLogo = styled.img`
  max-width: 9.0625rem;
  @media (max-width: 767px) {
    margin: 0 auto;
    display: block;
  }
`;

export const FormWrapper = styled.form`
  display: flex;
  margin-top: 0.9375rem;
  input {
    width: 15.625rem;
  }
  button {
    margin-left: 0.625rem;
  }
  @media (max-width: 767px) {
    justify-content: center;
  }
`;

export const InputWrapper = styled.span`
  display: block;
  overflow: hidden;
  padding-right: 0.625rem;
`;

export const Text = styled.span`
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
  color: grey;
  display: block;
  max-width: 48.75rem;
  margin: 0 auto;
  font-weight: 400;
  margin-top: 0.625rem;
  @media (max-width: 767px) {
    text-align: center;
  }
`;
